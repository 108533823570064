.checkbox {

  &-wrapper {
    padding-top: 0;
  }

  &-el {
    @include visually-hidden();
  }

  &-mark {
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    border: 1px solid $color-frame;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;

    &::after {
      content: "";
      width: 8px;
      height: 4px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-5px, -4px) rotate(-45deg) scale(0);
      border-left: 2px solid $color-white;
      border-bottom: 2px solid $color-white;
      opacity: 0
    }
  }

  &-el:checked + &-mark {
    border-color: $color-blue-whale;
    background-color: $color-blue-whale;

    &::after {
      transform: translate(-5px, -4px) rotate(-45deg) scale(1);
      opacity: 1;
    }
  }

  &-el:disabled + &-mark {
    cursor: default;
  }

  &-label {
    margin-left: 8px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
  }

  &-el:disabled ~ &-label {
    color: $color-frame;
    cursor: default;
  }
  
}