.footerLogo {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  cursor: pointer;
  
  img {
    vertical-align: middle;
    width: 150px;
  }
}