.dialog {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  opacity: 1;
  z-index: 500;
  transition: all .2s ease-out .0s, visibility 0s linear 0s;

  &--closed {
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease-out .0s, visibility 0s linear .2s;
  }

  &-overlay {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    overflow-y: auto;
    padding: 16px;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(#000, 0.8);
  }

  &-window {
    background-color: #fff;
    border-radius: 4px;
    position: relative;
    flex: 0 0 auto;
    transform: scale(1);
    transform-origin: 50% 100%;
    transition: all .2s ease-out .0s;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 800px;
  }

  &--closed &-window {
    transform: scale(0.8);
  }

  &-head {
    padding: 32px 32px 0;

    @include media("<medium") {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &-title {
    @include heading();
    
    line-height: 1.4;
    margin: 0;
    text-align: center;
    font-size: 28px;

    @include media("<medium") {
      font-size: 20px;
    }
  }

  &-content {
    padding: 32px;
    overflow-x: hidden;
    overflow-y: auto;

    @include media("<medium") {
      padding: 16px;
    }
  }

  &-close {
    -webkit-appearance: none;
    background: none;
    padding: 0;

    width: 20px;
    height: 20px;
    padding: 0;
    display: inline-block;
    background: none;
    border: none;
    cursor: pointer;

    position: absolute;
    color: $color-grey;

    right: 12px;
    top: 12px;

    &:hover {
      color: $color-blue-whale;
    }

    &:focus {
      outline: none;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      content: "";
      width: 100%;
      height: 1px;
      transform-origin: 50% 50%;
      transform: rotate(-45deg);
      background-color: currentColor;
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 0;
      content: "";
      width: 100%;
      height: 1px;
      transform-origin: 50% 50%;
      transform: rotate(45deg);
      background-color: currentColor;
    }
  }
}