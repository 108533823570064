.sectionFeedbackForm {
  background-color: $color-lavender;
  padding: 120px 0;

  @include media("<medium") {
    padding: 64px 0;
  }

  @include media("<small") {
    padding: 0 0;
    margin: -48px 0 0;
  }

  &--services {
    padding: 0;
  }

  &-title {
    @include heading();
    color: $color-primary;
    font-size: 36px;
    text-align: center;
    margin-bottom: 36px;

    @include media("<small") {
      font-size: 24px;
    }
  }
  
  &-box {
    max-width: 920px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 60px;
    background-color: $color-white;
    box-shadow: 0 0 40px 0 rgba(#23324c, 0.1);

    @include media("<small") {
      padding: 16px;
    }
  }

  &--services &-box {
    margin: 0;
    max-width: 100%;
    padding: 60px 88px;
  }
}