.sectionServiceList {
  padding: 16px 0 8px;

  &-row {
    display: flex;
    flex-wrap: wrap;
    margin: -16px;
    margin-bottom: 8px;

    @include media("<medium") {
      flex-direction: column;
    }
  }

  &-col {
    flex: 0 0 percentage(1/2);
    max-width: percentage(1/2);
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: stretch;

    @include media("<medium") {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 8px;
    }
  }
}