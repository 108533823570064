.servicePreview {
  display: flex;
  align-items: stretch;

  &-cover {
    position: relative;
    background-color: $color-blue-whale;
    padding: 40px 40px;
    display: flex;
    align-items: center;

    @include media("<medium") {
      padding: 24px 40px;
    }

    @include media("<medium") {
      padding: 16px 16px;
    }
  }

  &-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    opacity: 0.2;
    background-position: center;
    transition: all .1s ease-out;

    @include media("retina2x") {
      display: none;
    }

    &--retina {
      display: none;

      @include media("retina2x") {
        display: block;
      }
    }
  }

  &-link {
    text-decoration: none;
    color: inherit;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &-link &-title {
    @include heading();
    color: $color-white;
    font-size: 30px;
    letter-spacing: 0.6px;
    text-align: center;
    position: relative;

    @include media("<small") {
      font-size: 22px;
    }
  }
}