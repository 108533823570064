.newsPreviewSmall {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $color-gainsboro;
  padding: 16px 0;
  position: relative;
  transition: all .1s ease-out;

  @include media("<medium") {
    padding: 8px 0;
    justify-content: flex-start;
  }

  &:hover {
    transform: translateX(2px);
  }

  &-photoHolder {
    width: 68px;
    height: 68px;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 16px;
  }

  &-detailsHolder {
  }

  &-photo {
    vertical-align: middle;
    height: 100%;
  }

  &-title {
    font-size: 13px;
    font-weight: 600;
    margin: 0;

    @include media("<small") {
      font-size: 18px;
    }
  }

  &-link {
    text-decoration: none;
    color: inherit;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}