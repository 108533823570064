.form {
  overflow: visible;
  
  &-row {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
    margin-bottom: 8px;
    
    &--actions {
      justify-content: center;
    }
    
    &:last-child {
      margin-bottom: -8px;
    }
  }
}