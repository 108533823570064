.sectionHero {
  padding: 200px 0 24px;
  background-image: url(assets/images/hero-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;

  @include media("retina2x") {
    background-image: url(assets/images/hero-bg@2x.jpg);
    background-size: 1920px auto;
  }

  @include media("<medium") {
    background-size: cover;
  }

  &-container {

  }

  &-title {
    @include heading();
    font-size: 60px;
    margin: 60px 0 20px;
    text-shadow: 0 0 40px #fff;

    @include media("<medium") {
      font-size: 22px;
      margin: 20px 0 60px;
    }
  }

  &-cta {
    color: inherit;
    text-decoration: none;
    display: inline-block;

    @include button();

    @include media("<medium") {
      font-size: 14px;
      padding: 10px 24px;
      line-height: 24px;
    }
  }

  &-main {
    text-align: center;
  }

  &-counter {
    padding-top: 100px;

    @include media("<1043px") {
      padding-top: 16px; // Отнял высоту линии
    }
  }
}