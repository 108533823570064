.mustKnowList {
  list-style: none;
  max-width: 760px;
  margin: 56px auto;
  padding: 0;

  &-item {
    font-size: 22px;
    font-family: $font-merriweather;
    font-weight: 700;
    color: $color-blue-whale;
    padding-left: 64px;
    margin: 32px 0;
    display: block;
    position: relative;
    line-height: 1.8;

    @include media("<medium") {
      font-size: 20px;
    }

    @include media("<small") {
      font-size: 16px;
    }
  }

  &-itemNumber {
    display: inline-block;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 25px;
    background-color: #ffffff;
    box-shadow: 0 4px 10px 0 rgba($color-blue-whale, 0.15);
    position: absolute;
    left: 0;
    top: -6px;
    pointer-events: none;
    user-select: none;
  }
}