.headerPhone {
  text-decoration: none;
  color: inherit;
  display: block;
  
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.4px;

  @include media("<small") {
    font-size: 14px;
  }
}