.mustKnow {

  &-cover {
    position: relative;
    background-color: $color-blue-whale;
  }
  
  &-coverBack {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    opacity: 0.2;

    @include media("retina2x") {
      display: none;
    }
    
    &--retina {
      display: none;

      @include media("retina2x") {
        display: block;
      } 
    }  
  }

  &-caption {
    @include heading();
    position: relative;
    color: $color-text-inverse;
    font-size: 30px;
    line-height: 1.5;
    max-width: 800px;
    margin: 0 auto;
    padding: 96px 0;
    text-align: center;

    @include media("<medium") {
      font-size: 22px;
      padding: 24px 16px;
    }
  }

  &-list {
    @include media("<medium") {
      padding: 0 16px;
    }
  }

  &-actionHolder {
    text-align: center;
  }
  
  &-action {
    @include linkReset();
    @include button();
  }
}