.visible {

  &--large {
    @include media("<large") {
      display: none;
    }
  }

  &--medium {
    @include media("<medium") {
      display: none;
    }

    @include media(">=large") {
      display: none;
    }
  }

  &--small {
    @include media(">=medium") {
      display: none;
    }
  }
}