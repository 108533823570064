.sidebarItem {
  &-title {
    @include heading();
    font-size: 18px;
    margin-bottom: 32px;

    @include media("<medium") {
      text-align: center;
      font-size: 24px;
    }
  }
}