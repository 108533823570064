@import "normalize-scss/sass/_normalize.scss";

@include normalize();

html, 
body {
  font-family: $font-open-sans;
  font-size: 18px;
  line-height: 1.45;
  color: $color-nero;
  -webkit-font-smoothing: antialiased;

  @include media("<medium") {
    font-size: 18px;
  }
}