.header {
  padding: 16px 0 8px;
  
  &:after {
    content: "";
    background-size: 10px 6px;
    background-image: url(assets/images/chain.png);
    background-position: center;
    height: 6px;
    display: block;
    margin-top: 16px;
  }
  
  &-container {
    display: flex;
    align-items: center;
    margin: 0 -8px;

    @include media("<medium") {
      justify-content: space-between;
    }
  }
  
  &-col {
    &--logo {
      margin-right: auto;

      @include media("<medium") {
        display: none;
        flex: 0 1 0;
        width: 0;
        overflow: hidden;
      }
    }
    
    padding: 0 8px;
  }
  
  &-logo {
    
  }
  
  &-phones {
    
  }
  
  &-action {
    
  }
  
  &-messengers {
    text-align: right;
    
    @include media("<medium") {
      text-align: left;
    }
  }
  
  &-messengerIcon {
    width: 26px;
    height: 26px;
    
    @include media("<medium") {
      width: 24px;
      height: 24px;
    }
  }
}