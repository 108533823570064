.sectionMustKnow {

  &-cover {
    position: relative;
    background-color: $color-blue-whale;
    padding: 138px 0 176px;

    @include media("<medium") {
      padding: 64px 24px;
    }

    @include media("<small") {
      padding: 64px 16px;
    }
  }

  &-fadedBack {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    opacity: 0.3;
    background-position: center;

    @include media("retina2x") {
      display: none;
    }

    &--retina {
      display: none;

      @include media("retina2x") {
        display: block;
      }
    }

    &--secondary {
      opacity: 0.15;
    }
  }

  &-insert {
    position: relative;
    background-color: $color-denim;
    padding: 42px 0;

    @include media("<xmedium") {
      padding: 42px 24px;
    }

    @include media("<small") {
      padding: 24px 16px;
    }
  }

  &-wrapper {
    max-width: 760px;
    margin-right: auto;
    margin-left: auto;

    img {
      width: 100%;
    }
  }

  &-content {
    position: relative;
    text-align: center;

    @include media("<xmedium") {
      padding: 0 24px;
    }

    @include media("<small") {
      padding: 0 16px;
    }
  }

  &-title {
    @include heading();
    color: $color-text-inverse;
    font-size: 60px;
    font-weight: 700;
    display: inline-block;
    margin-bottom: 32px;

    @include media("<medium") {
      font-size: 28px;
      margin-bottom: 16px;
    }

    @include media("<small") {
      font-size: 24px;
      margin-bottom: 16px;
    }
  }

  &-caption {
    color: $color-text-inverse;
    font-size: 30px;
    line-height: 1.5;
    text-align: center;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;

    @include media("<xmedium") {
      padding: 0 56px;
    }

    @include media("<medium") {
      padding: 0 16px;
      font-size: 20px;
    }

    @include media("<small") {
      padding: 0 8px;
      font-size: 18px;
    }
  }

  &-header {
    @include heading();
    color: $color-nero;
    font-size: 27px;
    line-height: 2;

    @include media("<medium") {
      font-size: 22px;
    }

    @include media("<small") {
      font-size: 18px;
      line-height: 1.5;
    }
  }

  &-image {
    vertical-align: middle;
  }

  &-list {
    margin: 0;
    padding: 0;
    font-size: 22px;
    list-style: none;
    margin-top: 16px;

    @include media("<small") {
      font-size: 18px;
    }

    li {
      position: relative;
      padding-left: 48px;

      @include media("<small") {
        padding-left: 20px;
      }


      &::after {
        content: '';
        background-color: $color-nero;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 16px;


        @include media("<small") {
          padding-left: -24px;
        }
      }
    }
  }

  &-cargoShip {
    position: relative;
    background-color: $color-blue-whale;
    padding: 56px 0;

    @include media("<small") {
      padding: 32px 0;
    }
  }


  &-text {
    @include heading();
    line-height: 2;
    font-size: 22px;
    color: $color-text-inverse;
    text-align: left;
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;

    @include media("<medium") {
      font-size: 18px;
      line-height: 1.8;
    }

    @include media("<small") {
      font-size: 18px;
      line-height: 1.6;
    }
  }
}