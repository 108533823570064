@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("assets/fonts/Open Sans/OpenSans-Regular.woff2") format("woff2"), url("assets/fonts/Open Sans/OpenSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"), url("assets/fonts/Open Sans/OpenSans-SemiBold.woff2") format("woff2"), url("assets/fonts/Open Sans/OpenSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Merriweather';
  src: local("Merriweather"), local("Merriweather-Regular"), url("assets/fonts/Merriweather/Merriweather-Regular.woff2") format("woff2"), url("assets/fonts/Merriweather/Merriweather-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Merriweather';
  src: local("Merriweather Bold"), local("Merriweather-Bold"), url("assets/fonts/Merriweather/Merriweather-Bold.woff2") format("woff2"), url("assets/fonts/Merriweather/Merriweather-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

html,
body {
  font-family: "Open Sans", "Gill Sans", "Gill Sans MT", Verdana, sans-serif;
  font-size: 18px;
  line-height: 1.45;
  color: #1c1c1c;
  -webkit-font-smoothing: antialiased; }
  @media (max-width: 759px) {
    html,
    body {
      font-size: 18px; } }

.article {
  color: #1c1c1c; }
  .article-title {
    font-family: "Merriweather", Palatino, Georgia, serif;
    font-weight: 700;
    letter-spacing: 0.8px;
    color: #23324c;
    line-height: 1.4;
    font-size: 30px; }
    @media (max-width: 479px) {
      .article-title {
        font-size: 24px; } }
  .article-date {
    color: #545454;
    margin: 24px 0 22px;
    display: inline-block; }
  .article-content blockquote {
    margin: 0 -32px;
    padding: 32px;
    background-color: #f6fafe;
    font-size: 22px;
    letter-spacing: 0.3px;
    color: #23324c;
    font-weight: 700; }
    @media (max-width: 479px) {
      .article-content blockquote {
        font-size: 18px;
        margin: 0;
        padding: 16px; } }
  .article-content img {
    width: 100%; }

.articleGrid {
  display: flex;
  margin: -32px; }
  @media (max-width: 759px) {
    .articleGrid {
      justify-content: center;
      flex-direction: column; } }
  .articleGrid-main {
    flex: 1 1 auto;
    padding: 32px;
    box-sizing: border-box; }
    @media (max-width: 759px) {
      .articleGrid-main {
        flex: 0 0 auto; } }
  .articleGrid-side {
    flex: 0 0 360px;
    max-width: 360px;
    padding: 32px;
    box-sizing: border-box; }
    @media (max-width: 759px) {
      .articleGrid-side {
        flex: 0 0 auto;
        max-width: 100%; } }

.container {
  max-width: 1200px;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  padding: 0 16px; }

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  background-color: #f8f8f8; }
  @media (max-width: 759px) {
    .footer {
      flex-direction: column; } }
  .footer-link {
    text-decoration: none;
    color: inherit;
    display: inline-block;
    outline: none;
    font-size: 14px;
    text-transform: uppercase;
    padding: 8px 16px;
    cursor: pointer; }
    .footer-link:hover {
      text-decoration: underline; }
  .footer-logo {
    padding: 0 16px; }
    @media (max-width: 759px) {
      .footer-logo {
        order: -1;
        padding-top: 8px;
        padding-bottom: 8px; } }

.footerLogo {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  cursor: pointer; }
  .footerLogo img {
    vertical-align: middle;
    width: 150px; }

.heroCounter {
  display: flex;
  align-items: flex-start; }
  .heroCounter-col {
    flex: 1 1 auto;
    text-align: center;
    padding: 0 8px; }
    .heroCounter-col--sep {
      flex: 0 0 auto;
      width: 1px;
      background-color: rgba(255, 255, 255, 0.8);
      align-self: stretch;
      padding: 0; }
  .heroCounter-caption {
    color: #fff;
    font-family: "Merriweather", Palatino, Georgia, serif;
    font-size: 22px; }
    @media (max-width: 759px) {
      .heroCounter-caption {
        font-family: "Open Sans", "Gill Sans", "Gill Sans MT", Verdana, sans-serif;
        font-size: 16px; } }
    @media (max-width: 479px) {
      .heroCounter-caption {
        font-size: 14px; } }
  .heroCounter-number {
    color: #fff;
    font-family: "Merriweather", Palatino, Georgia, serif;
    font-size: 76px;
    font-weight: 700;
    line-height: 1.2; }
    @media (max-width: 759px) {
      .heroCounter-number {
        font-size: 26px; } }
    @media (max-width: 479px) {
      .heroCounter-number {
        font-size: 22px; } }

.landingQuote {
  position: relative;
  border: 3px dotted #23324c;
  border-radius: 24px;
  margin-top: 16px; }
  @media (max-width: 759px) {
    .landingQuote {
      border-width: 2px;
      border-radius: 16px; } }
  .landingQuote::before {
    content: "";
    background-image: url("assets/images/quote.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #fff;
    position: absolute;
    top: 0;
    margin-top: -18px;
    width: 48px;
    height: 38px;
    left: 48px;
    padding: 0 24px; }
    @media (max-width: 759px) {
      .landingQuote::before {
        background-size: contain;
        width: 24px;
        height: 19px;
        left: 16px;
        margin-top: -10px;
        padding: 0 4px; } }
  .landingQuote::after {
    content: "";
    background-image: url("assets/images/quote-flip.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    margin-bottom: -18px;
    width: 48px;
    height: 38px;
    right: 48px;
    padding: 0 24px; }
    @media (max-width: 759px) {
      .landingQuote::after {
        background-size: contain;
        width: 24px;
        height: 19px;
        right: 16px;
        margin-bottom: -10px;
        padding: 0 4px; } }
  .landingQuote--bgLavender::before {
    background-color: #f5f7fd; }
  .landingQuote--bgLavender::after {
    background-color: #f5f7fd; }
  .landingQuote-titleHolder {
    display: block;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 0; }
  .landingQuote-title {
    font-family: "Merriweather", Palatino, Georgia, serif;
    font-weight: 700;
    letter-spacing: 0.8px;
    color: #23324c;
    line-height: 1.4;
    font-size: 30px;
    line-height: 30px;
    position: relative;
    top: -15px;
    padding: 0 32px;
    display: inline-block;
    background-color: #fff; }
    @media (max-width: 759px) {
      .landingQuote-title {
        font-size: 22px;
        padding: 0 8px; } }
    @media (max-width: 479px) {
      .landingQuote-title {
        font-size: 18px; } }
  .landingQuote--bgLavender .landingQuote-title {
    background-color: #f5f7fd; }
  .landingQuote--bigger .landingQuote-title {
    font-size: 36px; }
    @media (max-width: 759px) {
      .landingQuote--bigger .landingQuote-title {
        font-size: 22px; } }
    @media (max-width: 479px) {
      .landingQuote--bigger .landingQuote-title {
        font-size: 18px; } }
  .landingQuote-text {
    font-size: 20px;
    padding: 36px 36px 24px; }
    @media (max-width: 759px) {
      .landingQuote-text {
        font-size: inherit;
        padding: 24px 16px 16px; } }
    @media (max-width: 479px) {
      .landingQuote-text {
        font-size: 16px; } }
  .landingQuote--bigger .landingQuote-text {
    font-weight: 600; }

.map {
  width: 100%;
  height: 100%;
  background-color: rgba(35, 50, 76, 0.1); }

.mustKnow-cover {
  position: relative;
  background-color: #23324c; }

.mustKnow-coverBack {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  opacity: 0.2; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
    .mustKnow-coverBack {
      display: none; } }
  .mustKnow-coverBack--retina {
    display: none; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
      .mustKnow-coverBack--retina {
        display: block; } }

.mustKnow-caption {
  font-family: "Merriweather", Palatino, Georgia, serif;
  font-weight: 700;
  letter-spacing: 0.8px;
  color: #23324c;
  line-height: 1.4;
  position: relative;
  color: #fff;
  font-size: 30px;
  line-height: 1.5;
  max-width: 800px;
  margin: 0 auto;
  padding: 96px 0;
  text-align: center; }
  @media (max-width: 759px) {
    .mustKnow-caption {
      font-size: 22px;
      padding: 24px 16px; } }

@media (max-width: 759px) {
  .mustKnow-list {
    padding: 0 16px; } }

.mustKnow-actionHolder {
  text-align: center; }

.mustKnow-action {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  outline: none;
  border-radius: 3px;
  border: 1px solid transparent;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.8px;
  font-size: inherit;
  font-weight: 600;
  line-height: 24px;
  padding: 16px 32px;
  user-select: none;
  box-sizing: border-box; }
  @media (max-width: 759px) {
    .mustKnow-action {
      font-size: 18px;
      line-height: 32px; } }
  @media (max-width: 479px) {
    .mustKnow-action {
      font-size: 16px;
      line-height: 20px; } }
  .mustKnow-action--primary {
    background-color: #9c2836;
    color: #fff;
    box-shadow: 0 4px 10px 0 rgba(156, 40, 54, 0.15);
    transition: all .1s ease-out;
    position: relative;
    text-transform: uppercase; }
    .mustKnow-action--primary:hover {
      background-color: #cd585a; }
    .mustKnow-action--primary:active {
      background-color: #8d102a;
      transform: translateY(1px); }
    .mustKnow-action--primary:focus {
      box-shadow: 0 0 0 4px rgba(156, 40, 54, 0.2); }
    .mustKnow-action--primary:disabled {
      opacity: 0.8; }
  .mustKnow-action--smaller {
    line-height: 24px;
    padding: 12px 32px;
    font-size: 14px; }
  .mustKnow-action--regular {
    border: 1px solid #23324c;
    color: #23324c;
    transition: all .1s ease-out;
    text-transform: none; }
    .mustKnow-action--regular:hover {
      background-color: #23324c;
      color: #fff; }
    .mustKnow-action--regular:focus {
      box-shadow: 0 0 0 4px rgba(35, 50, 76, 0.2); }
    .mustKnow-action--regular:disabled {
      opacity: 0.8; }
  .mustKnow-action--red {
    border: 1px solid #9c2836;
    color: #9c2836; }
    .mustKnow-action--red:hover {
      background-color: #cd585a;
      border-color: #cd585a; }
    .mustKnow-action--red:focus {
      box-shadow: 0 0 0 4px rgba(156, 40, 54, 0.2); }
    .mustKnow-action--red:disabled {
      opacity: 0.8; }
  .mustKnow-action--uppercase {
    text-transform: uppercase; }
  .mustKnow-action--full {
    width: 100%; }

.mustKnowList {
  list-style: none;
  max-width: 760px;
  margin: 56px auto;
  padding: 0; }
  .mustKnowList-item {
    font-size: 22px;
    font-family: "Merriweather", Palatino, Georgia, serif;
    font-weight: 700;
    color: #23324c;
    padding-left: 64px;
    margin: 32px 0;
    display: block;
    position: relative;
    line-height: 1.8; }
    @media (max-width: 759px) {
      .mustKnowList-item {
        font-size: 20px; } }
    @media (max-width: 479px) {
      .mustKnowList-item {
        font-size: 16px; } }
  .mustKnowList-itemNumber {
    display: inline-block;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 25px;
    background-color: #ffffff;
    box-shadow: 0 4px 10px 0 rgba(35, 50, 76, 0.15);
    position: absolute;
    left: 0;
    top: -6px;
    pointer-events: none;
    user-select: none; }

.navigation-container {
  position: relative; }
  @media (max-width: 759px) {
    .navigation-container {
      display: flex;
      justify-content: space-between;
      align-items: center; } }

@media (max-width: 759px) {
  .navigation-col--list {
    width: 0;
    flex: 0 1 0;
    overflow: visible; } }

@media (min-width: 760px) {
  .navigation-col--opener {
    display: none;
    width: 0;
    flex: 0 1 0;
    overflow: hidden; } }

.navigation-logo {
  width: 150px; }
  @media (min-width: 760px) {
    .navigation-logo {
      display: none; } }

.navigation-sandwich {
  -webkit-appearance: none;
  text-decoration: none;
  background: none;
  border: none;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
  font-family: inherit;
  width: 40px;
  height: 40px;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  color: #23324c;
  z-index: 22;
  cursor: pointer; }
  .navigation-sandwich:focus {
    outline: none; }

.navigation-sandwichIcon {
  height: 2px;
  width: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -1px;
  background-color: transparent;
  transition: background-color .1s ease-out; }
  .navigation-sandwichIcon::before, .navigation-sandwichIcon::after {
    content: "";
    height: inherit;
    width: inherit;
    position: absolute;
    top: 0;
    left: 0;
    background-color: currentColor;
    transition: all .2s ease-out; }
  .navigation-sandwichIcon::before {
    transform: rotate(-45deg); }
  .navigation-sandwichIcon::after {
    transform: rotate(45deg); }

.navigation--closed .navigation-sandwichIcon {
  background-color: currentColor; }
  .navigation--closed .navigation-sandwichIcon::before {
    transform: translate(0, -8px); }
  .navigation--closed .navigation-sandwichIcon::after {
    transform: translate(0, 8px); }

@media (max-width: 759px) {
  .navigation-list {
    position: absolute;
    width: 280px;
    right: 0;
    top: 0;
    background-color: #fff;
    box-shadow: 0 3px 8px rgba(131, 131, 131, 0.17);
    z-index: 19;
    transform-origin: 100% 0;
    opacity: 1;
    transition: all .2s ease-out 0s, z-index 0s linear 0s; } }

@media (max-width: 759px) {
  .navigation--closed .navigation-list {
    transform: scale(0.6);
    z-index: -1;
    opacity: 0;
    transition: all .2s ease-out 0s, z-index 0s linear .2s; } }

.navigation-listTitle {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 16px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: #545454; }
  @media (min-width: 760px) {
    .navigation-listTitle {
      display: none; } }

.navigation-link {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  padding: 16px  0;
  margin-right: 16px;
  border-bottom: 3px solid transparent;
  vertical-align: middle;
  font-size: 14px;
  text-transform: uppercase; }
  .navigation-link:hover {
    border-bottom-color: #9c2836; }
  .navigation-link:last-child {
    margin-right: 0; }
  @media (max-width: 759px) {
    .navigation-link {
      width: 100%;
      box-sizing: border-box;
      padding-left: 16px;
      padding-right: 16px;
      margin-right: 0; } }

.newsList-all {
  display: flex;
  margin: -16px;
  flex-wrap: wrap; }

.newsList-item {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  box-sizing: border-box;
  padding: 16px; }
  @media (max-width: 1023px) {
    .newsList-item {
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (max-width: 759px) {
    .newsList-item {
      flex: 0 0 100%;
      max-width: 100%; } }

.newsList-moreHolder {
  text-align: center;
  margin-top: 36px; }

.newsList-more {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  outline: none;
  border-radius: 3px;
  border: 1px solid transparent;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.8px;
  font-size: inherit;
  font-weight: 600;
  line-height: 24px;
  padding: 16px 32px;
  user-select: none;
  box-sizing: border-box;
  position: relative; }
  @media (max-width: 759px) {
    .newsList-more {
      font-size: 18px;
      line-height: 32px; } }
  @media (max-width: 479px) {
    .newsList-more {
      font-size: 16px;
      line-height: 20px; } }
  .newsList-more--primary {
    background-color: #9c2836;
    color: #fff;
    box-shadow: 0 4px 10px 0 rgba(156, 40, 54, 0.15);
    transition: all .1s ease-out;
    position: relative;
    text-transform: uppercase; }
    .newsList-more--primary:hover {
      background-color: #cd585a; }
    .newsList-more--primary:active {
      background-color: #8d102a;
      transform: translateY(1px); }
    .newsList-more--primary:focus {
      box-shadow: 0 0 0 4px rgba(156, 40, 54, 0.2); }
    .newsList-more--primary:disabled {
      opacity: 0.8; }
  .newsList-more--smaller {
    line-height: 24px;
    padding: 12px 32px;
    font-size: 14px; }
  .newsList-more--regular {
    border: 1px solid #23324c;
    color: #23324c;
    transition: all .1s ease-out;
    text-transform: none; }
    .newsList-more--regular:hover {
      background-color: #23324c;
      color: #fff; }
    .newsList-more--regular:focus {
      box-shadow: 0 0 0 4px rgba(35, 50, 76, 0.2); }
    .newsList-more--regular:disabled {
      opacity: 0.8; }
  .newsList-more--red {
    border: 1px solid #9c2836;
    color: #9c2836; }
    .newsList-more--red:hover {
      background-color: #cd585a;
      border-color: #cd585a; }
    .newsList-more--red:focus {
      box-shadow: 0 0 0 4px rgba(156, 40, 54, 0.2); }
    .newsList-more--red:disabled {
      opacity: 0.8; }
  .newsList-more--uppercase {
    text-transform: uppercase; }
  .newsList-more--full {
    width: 100%; }

.newsPreview {
  position: relative;
  box-shadow: 0 4px 40px 0 rgba(35, 50, 76, 0.1);
  transition: all .1s ease-out; }
  .newsPreview:hover {
    transform: translateY(2px);
    box-shadow: 0 0 20px 0 rgba(35, 50, 76, 0.15); }
  .newsPreview-photoHolder {
    height: 220px;
    overflow: hidden;
    background-color: #23324c; }
    @media (max-width: 1023px) {
      .newsPreview-photoHolder {
        height: auto; } }
  .newsPreview-photo {
    vertical-align: middle;
    width: 100%; }
  .newsPreview-detailsHolder {
    height: 220px;
    padding: 32px 32px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    background-color: #fff; }
    @media (max-width: 759px) {
      .newsPreview-detailsHolder {
        padding: 16px;
        height: auto; } }
  .newsPreview-title {
    font-weight: 700;
    font-size: 18px;
    margin: 0;
    line-height: 1.6; }
    @media (max-width: 759px) {
      .newsPreview-title {
        font-size: 20px; } }
    @media (max-width: 479px) {
      .newsPreview-title {
        font-size: 18px; } }
  .newsPreview-link {
    flex: 1 1 auto;
    text-decoration: none;
    color: inherit; }
    .newsPreview-link::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
  .newsPreview-date {
    flex: 0 0 auto;
    color: #545454;
    margin-top: 16px; }

.newsPreviewSmall {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #d9d9d9;
  padding: 16px 0;
  position: relative;
  transition: all .1s ease-out; }
  @media (max-width: 759px) {
    .newsPreviewSmall {
      padding: 8px 0;
      justify-content: flex-start; } }
  .newsPreviewSmall:hover {
    transform: translateX(2px); }
  .newsPreviewSmall-photoHolder {
    width: 68px;
    height: 68px;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 16px; }
  .newsPreviewSmall-photo {
    vertical-align: middle;
    height: 100%; }
  .newsPreviewSmall-title {
    font-size: 13px;
    font-weight: 600;
    margin: 0; }
    @media (max-width: 479px) {
      .newsPreviewSmall-title {
        font-size: 18px; } }
  .newsPreviewSmall-link {
    text-decoration: none;
    color: inherit; }
    .newsPreviewSmall-link::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }

.page {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start; }
  .page--headOverlay .page-header {
    position: absolute;
    left: 0;
    right: 0;
    top: 0; }
  .page-content {
    flex: 1 0 auto; }

.providedServices-slide {
  position: relative;
  height: 100%; }

.providedServices-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
    .providedServices-bg {
      display: none; } }
  .providedServices-bg--retina {
    display: none; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
      .providedServices-bg--retina {
        display: block; } }

.providedServices-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(90deg, rgba(35, 50, 76, 0.9), rgba(35, 50, 76, 0.7) 60%, rgba(35, 50, 76, 0.2)); }

.providedServices-content {
  position: relative;
  z-index: 2;
  padding: 40px 0;
  max-width: 600px; }

.providedServices-title {
  font-family: "Merriweather", Palatino, Georgia, serif;
  font-weight: 700;
  letter-spacing: 0.8px;
  color: #23324c;
  line-height: 1.4;
  font-size: 30px;
  line-height: 1.5;
  color: #fff;
  margin: 0; }
  @media (max-width: 759px) {
    .providedServices-title {
      font-size: 20px; } }

.providedServices-text {
  font-size: 20px;
  margin: 32px 0;
  color: #fff; }
  @media (max-width: 759px) {
    .providedServices-text {
      font-size: inherit; } }

.providedServices-moreHolder {
  text-align: center;
  margin-top: 36px; }

.providedServices-more {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  outline: none;
  border-radius: 3px;
  border: 1px solid transparent;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.8px;
  font-size: inherit;
  font-weight: 600;
  line-height: 24px;
  padding: 16px 32px;
  user-select: none;
  box-sizing: border-box;
  position: relative; }
  @media (max-width: 759px) {
    .providedServices-more {
      font-size: 18px;
      line-height: 32px; } }
  @media (max-width: 479px) {
    .providedServices-more {
      font-size: 16px;
      line-height: 20px; } }
  .providedServices-more--primary {
    background-color: #9c2836;
    color: #fff;
    box-shadow: 0 4px 10px 0 rgba(156, 40, 54, 0.15);
    transition: all .1s ease-out;
    position: relative;
    text-transform: uppercase; }
    .providedServices-more--primary:hover {
      background-color: #cd585a; }
    .providedServices-more--primary:active {
      background-color: #8d102a;
      transform: translateY(1px); }
    .providedServices-more--primary:focus {
      box-shadow: 0 0 0 4px rgba(156, 40, 54, 0.2); }
    .providedServices-more--primary:disabled {
      opacity: 0.8; }
  .providedServices-more--smaller {
    line-height: 24px;
    padding: 12px 32px;
    font-size: 14px; }
  .providedServices-more--regular {
    border: 1px solid #23324c;
    color: #23324c;
    transition: all .1s ease-out;
    text-transform: none; }
    .providedServices-more--regular:hover {
      background-color: #23324c;
      color: #fff; }
    .providedServices-more--regular:focus {
      box-shadow: 0 0 0 4px rgba(35, 50, 76, 0.2); }
    .providedServices-more--regular:disabled {
      opacity: 0.8; }
  .providedServices-more--red {
    border: 1px solid #9c2836;
    color: #9c2836; }
    .providedServices-more--red:hover {
      background-color: #cd585a;
      border-color: #cd585a; }
    .providedServices-more--red:focus {
      box-shadow: 0 0 0 4px rgba(156, 40, 54, 0.2); }
    .providedServices-more--red:disabled {
      opacity: 0.8; }
  .providedServices-more--uppercase {
    text-transform: uppercase; }
  .providedServices-more--full {
    width: 100%; }

.sectionArticle {
  padding: 56px 0 72px; }
  @media (max-width: 479px) {
    .sectionArticle {
      padding: 0; } }

.sectionArticleList {
  margin: 56px 0; }
  @media (max-width: 759px) {
    .sectionArticleList {
      margin: 40px 0; } }

.sectionContacts-title {
  font-family: "Merriweather", Palatino, Georgia, serif;
  font-weight: 700;
  letter-spacing: 0.8px;
  color: #23324c;
  line-height: 1.4;
  font-size: 36px;
  color: #9c2836;
  text-align: center;
  max-width: 800px;
  margin: 60px 0;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 759px) {
    .sectionContacts-title {
      font-size: 24px;
      margin: 24px 0; } }

.sectionContacts-details {
  display: flex;
  margin: -16px; }
  @media (max-width: 759px) {
    .sectionContacts-details {
      flex-direction: column;
      margin-bottom: 24px; } }

.sectionContacts-col {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  text-align: center;
  padding: 16px;
  box-sizing: border-box; }
  @media (max-width: 759px) {
    .sectionContacts-col {
      flex: 0 0 auto;
      max-width: none; } }

.sectionContacts-itemIcon {
  display: inline-block;
  width: 28px;
  height: 36px; }

.sectionContacts-map {
  margin-top: 60px;
  height: 480px; }

.sectionContacts-additionIcon {
  width: 26px;
  height: 26px; }

.sectionContactsDetailed-row {
  display: flex;
  margin: 0 -16px;
  align-items: flex-start; }
  @media (max-width: 959px) {
    .sectionContactsDetailed-row {
      flex-direction: column;
      align-items: center; } }

.sectionContactsDetailed-col {
  padding: 0 16px;
  font-size: 20px;
  box-sizing: border-box; }
  @media (max-width: 959px) {
    .sectionContactsDetailed-col {
      align-self: stretch; } }
  .sectionContactsDetailed-col--map {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
    @media (max-width: 959px) {
      .sectionContactsDetailed-col--map {
        flex-basis: auto;
        max-width: none; } }
    @media (max-width: 759px) {
      .sectionContactsDetailed-col--map {
        order: 1; } }
  .sectionContactsDetailed-col--content {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
    @media (max-width: 959px) {
      .sectionContactsDetailed-col--content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-basis: auto;
        max-width: none; } }
    @media (max-width: 759px) {
      .sectionContactsDetailed-col--content {
        padding-bottom: 32px; } }

.sectionContactsDetailed-mapWrapper {
  width: 100%;
  height: 340px; }
  @media (max-width: 479px) {
    .sectionContactsDetailed-mapWrapper {
      text-align: center;
      height: 200px; } }

.sectionContactsDetailed-title {
  font-family: "Merriweather", Palatino, Georgia, serif;
  font-weight: 700;
  letter-spacing: 0.8px;
  color: #23324c;
  line-height: 1.4;
  color: #9c2836;
  font-size: 22px;
  letter-spacing: 0.2px;
  margin-bottom: 24px;
  margin-top: 0; }
  @media (max-width: 959px) {
    .sectionContactsDetailed-title {
      text-align: center;
      flex: 0 0 100%;
      margin-top: 16px; } }

.sectionContactsDetailed-rowItem {
  display: flex;
  margin: 0 -16px;
  align-items: flex-start;
  padding-bottom: 16px; }
  @media (max-width: 959px) {
    .sectionContactsDetailed-rowItem {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
      flex-direction: column;
      justify-content: flex-start;
      margin: 0; } }
  @media (max-width: 759px) {
    .sectionContactsDetailed-rowItem {
      flex: 0 0 100%;
      max-width: none;
      flex-direction: row;
      align-content: center;
      margin: 8px -16px; } }
  @media (max-width: 479px) {
    .sectionContactsDetailed-rowItem {
      margin: 8px -6px; } }

.sectionContactsDetailed-colItem {
  padding: 0 16px;
  font-size: 20px;
  box-sizing: border-box; }
  @media (max-width: 959px) {
    .sectionContactsDetailed-colItem {
      padding: 0;
      align-self: center;
      text-align: center; } }
  @media (max-width: 759px) {
    .sectionContactsDetailed-colItem {
      padding: 0 16px;
      text-align: left; } }
  @media (max-width: 479px) {
    .sectionContactsDetailed-colItem {
      align-self: flex-start;
      padding: 0 6px;
      font-size: 16px; } }

.sectionContactsDetailed-itemIcon {
  display: inline-block;
  width: 20px;
  height: 26px;
  padding-top: 8px; }
  @media (max-width: 959px) {
    .sectionContactsDetailed-itemIcon {
      padding: 0; } }
  @media (max-width: 759px) {
    .sectionContactsDetailed-itemIcon {
      margin: 0; } }

.sectionContactsDetailed-additionIcon {
  width: 26px;
  height: 26px;
  display: inline-block;
  vertical-align: middle; }

.sectionContactsForm {
  background-color: #fff;
  padding: 48px 0; }
  @media (max-width: 479px) {
    .sectionContactsForm {
      padding: 24px 0; } }
  .sectionContactsForm-title {
    font-family: "Merriweather", Palatino, Georgia, serif;
    font-weight: 700;
    letter-spacing: 0.8px;
    color: #23324c;
    line-height: 1.4;
    color: #9c2836;
    font-size: 36px;
    text-align: center;
    margin: 0 auto;
    max-width: 870px; }
    @media (max-width: 759px) {
      .sectionContactsForm-title {
        font-size: 30px; } }
    @media (max-width: 479px) {
      .sectionContactsForm-title {
        font-size: 20px; } }
  .sectionContactsForm-subtitle {
    text-align: center;
    font-size: 20px;
    margin-bottom: 36px; }
    @media (max-width: 759px) {
      .sectionContactsForm-subtitle {
        font-size: inherit;
        margin-bottom: 16px; } }
  .sectionContactsForm-content {
    max-width: 768px;
    margin: 0 auto; }
  .sectionContactsForm-box {
    box-sizing: border-box;
    padding: 40px 0;
    background-color: #fff;
    box-shadow: 0 0 40px 0 rgba(35, 50, 76, 0.1); }
    @media (max-width: 959px) {
      .sectionContactsForm-box {
        padding: 40px 24px; } }
    @media (max-width: 479px) {
      .sectionContactsForm-box {
        padding: 32px 16px; } }

.sectionError {
  text-align: center;
  background-image: url(assets/images/404-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh; }
  .sectionError-wrapper {
    flex: 0 0 auto; }
  .sectionError-image {
    vertical-align: middle; }
    @media (max-width: 759px) {
      .sectionError-image {
        width: 100%; } }
  .sectionError-text {
    font-family: "Merriweather", Palatino, Georgia, serif;
    font-weight: 700;
    letter-spacing: 0.8px;
    color: #23324c;
    line-height: 1.4;
    font-size: 40px;
    color: #000;
    margin: 0; }
    @media (max-width: 759px) {
      .sectionError-text {
        font-size: 32px; } }
  .sectionError-buttonHolder {
    margin-top: 24px;
    text-align: center; }
  .sectionError-button {
    text-decoration: none;
    color: inherit;
    display: inline-block;
    outline: none;
    border-radius: 3px;
    border: 1px solid transparent;
    text-align: center;
    cursor: pointer;
    letter-spacing: 0.8px;
    font-size: inherit;
    font-weight: 600;
    line-height: 24px;
    padding: 16px 32px;
    user-select: none;
    box-sizing: border-box; }
    @media (max-width: 759px) {
      .sectionError-button {
        font-size: 18px;
        line-height: 32px; } }
    @media (max-width: 479px) {
      .sectionError-button {
        font-size: 16px;
        line-height: 20px; } }
    .sectionError-button--primary {
      background-color: #9c2836;
      color: #fff;
      box-shadow: 0 4px 10px 0 rgba(156, 40, 54, 0.15);
      transition: all .1s ease-out;
      position: relative;
      text-transform: uppercase; }
      .sectionError-button--primary:hover {
        background-color: #cd585a; }
      .sectionError-button--primary:active {
        background-color: #8d102a;
        transform: translateY(1px); }
      .sectionError-button--primary:focus {
        box-shadow: 0 0 0 4px rgba(156, 40, 54, 0.2); }
      .sectionError-button--primary:disabled {
        opacity: 0.8; }
    .sectionError-button--smaller {
      line-height: 24px;
      padding: 12px 32px;
      font-size: 14px; }
    .sectionError-button--regular {
      border: 1px solid #23324c;
      color: #23324c;
      transition: all .1s ease-out;
      text-transform: none; }
      .sectionError-button--regular:hover {
        background-color: #23324c;
        color: #fff; }
      .sectionError-button--regular:focus {
        box-shadow: 0 0 0 4px rgba(35, 50, 76, 0.2); }
      .sectionError-button--regular:disabled {
        opacity: 0.8; }
    .sectionError-button--red {
      border: 1px solid #9c2836;
      color: #9c2836; }
      .sectionError-button--red:hover {
        background-color: #cd585a;
        border-color: #cd585a; }
      .sectionError-button--red:focus {
        box-shadow: 0 0 0 4px rgba(156, 40, 54, 0.2); }
      .sectionError-button--red:disabled {
        opacity: 0.8; }
    .sectionError-button--uppercase {
      text-transform: uppercase; }
    .sectionError-button--full {
      width: 100%; }

.sectionFeedbackForm {
  background-color: #f5f7fd;
  padding: 120px 0; }
  @media (max-width: 759px) {
    .sectionFeedbackForm {
      padding: 64px 0; } }
  @media (max-width: 479px) {
    .sectionFeedbackForm {
      padding: 0 0;
      margin: -48px 0 0; } }
  .sectionFeedbackForm--services {
    padding: 0; }
  .sectionFeedbackForm-title {
    font-family: "Merriweather", Palatino, Georgia, serif;
    font-weight: 700;
    letter-spacing: 0.8px;
    color: #23324c;
    line-height: 1.4;
    color: #9c2836;
    font-size: 36px;
    text-align: center;
    margin-bottom: 36px; }
    @media (max-width: 479px) {
      .sectionFeedbackForm-title {
        font-size: 24px; } }
  .sectionFeedbackForm-box {
    max-width: 920px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 60px;
    background-color: #fff;
    box-shadow: 0 0 40px 0 rgba(35, 50, 76, 0.1); }
    @media (max-width: 479px) {
      .sectionFeedbackForm-box {
        padding: 16px; } }
  .sectionFeedbackForm--services .sectionFeedbackForm-box {
    margin: 0;
    max-width: 100%;
    padding: 60px 88px; }

.sectionHero {
  padding: 200px 0 24px;
  background-image: url(assets/images/hero-bg.jpg);
  background-repeat: no-repeat;
  background-position: center; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
    .sectionHero {
      background-image: url(assets/images/hero-bg@2x.jpg);
      background-size: 1920px auto; } }
  @media (max-width: 759px) {
    .sectionHero {
      background-size: cover; } }
  .sectionHero-title {
    font-family: "Merriweather", Palatino, Georgia, serif;
    font-weight: 700;
    letter-spacing: 0.8px;
    color: #23324c;
    line-height: 1.4;
    font-size: 60px;
    margin: 60px 0 20px;
    text-shadow: 0 0 40px #fff; }
    @media (max-width: 759px) {
      .sectionHero-title {
        font-size: 22px;
        margin: 20px 0 60px; } }
  .sectionHero-cta {
    color: inherit;
    text-decoration: none;
    display: inline-block;
    border-radius: 3px;
    border: 1px solid transparent;
    text-align: center;
    cursor: pointer;
    letter-spacing: 0.8px;
    font-size: inherit;
    font-weight: 600;
    line-height: 24px;
    padding: 16px 32px;
    user-select: none;
    box-sizing: border-box; }
    @media (max-width: 759px) {
      .sectionHero-cta {
        font-size: 18px;
        line-height: 32px; } }
    @media (max-width: 479px) {
      .sectionHero-cta {
        font-size: 16px;
        line-height: 20px; } }
    .sectionHero-cta--primary {
      background-color: #9c2836;
      color: #fff;
      box-shadow: 0 4px 10px 0 rgba(156, 40, 54, 0.15);
      transition: all .1s ease-out;
      position: relative;
      text-transform: uppercase; }
      .sectionHero-cta--primary:hover {
        background-color: #cd585a; }
      .sectionHero-cta--primary:active {
        background-color: #8d102a;
        transform: translateY(1px); }
      .sectionHero-cta--primary:focus {
        box-shadow: 0 0 0 4px rgba(156, 40, 54, 0.2); }
      .sectionHero-cta--primary:disabled {
        opacity: 0.8; }
    .sectionHero-cta--smaller {
      line-height: 24px;
      padding: 12px 32px;
      font-size: 14px; }
    .sectionHero-cta--regular {
      border: 1px solid #23324c;
      color: #23324c;
      transition: all .1s ease-out;
      text-transform: none; }
      .sectionHero-cta--regular:hover {
        background-color: #23324c;
        color: #fff; }
      .sectionHero-cta--regular:focus {
        box-shadow: 0 0 0 4px rgba(35, 50, 76, 0.2); }
      .sectionHero-cta--regular:disabled {
        opacity: 0.8; }
    .sectionHero-cta--red {
      border: 1px solid #9c2836;
      color: #9c2836; }
      .sectionHero-cta--red:hover {
        background-color: #cd585a;
        border-color: #cd585a; }
      .sectionHero-cta--red:focus {
        box-shadow: 0 0 0 4px rgba(156, 40, 54, 0.2); }
      .sectionHero-cta--red:disabled {
        opacity: 0.8; }
    .sectionHero-cta--uppercase {
      text-transform: uppercase; }
    .sectionHero-cta--full {
      width: 100%; }
    @media (max-width: 759px) {
      .sectionHero-cta {
        font-size: 14px;
        padding: 10px 24px;
        line-height: 24px; } }
  .sectionHero-main {
    text-align: center; }
  .sectionHero-counter {
    padding-top: 100px; }
    @media (max-width: 1042px) {
      .sectionHero-counter {
        padding-top: 16px; } }

.sectionIntro {
  background-color: #f5f7fd;
  overflow: hidden; }
  .sectionIntro-container {
    display: flex;
    margin: -16px; }
    @media (max-width: 759px) {
      .sectionIntro-container {
        flex-direction: column; } }
  .sectionIntro-col {
    flex: 1 1 auto;
    box-sizing: border-box;
    padding: 16px; }
    .sectionIntro-col--photo {
      flex: 0 0 420px;
      min-width: 420px;
      box-sizing: border-box;
      align-self: flex-end; }
      @media (max-width: 759px) {
        .sectionIntro-col--photo {
          min-width: auto;
          display: none; } }
    @media (max-width: 759px) {
      .sectionIntro-col {
        flex: 0 0 auto; } }
  .sectionIntro-quote {
    margin-bottom: 32px; }
    @media (max-width: 759px) {
      .sectionIntro-quote {
        margin-bottom: 16px; } }
  .sectionIntro-subtitle {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.4px;
    text-align: center;
    margin-top: 60px;
    padding: 16px 0; }
    @media (max-width: 759px) {
      .sectionIntro-subtitle {
        font-size: 20px;
        margin-top: 24px; } }
    @media (max-width: 479px) {
      .sectionIntro-subtitle {
        font-size: 18px; } }
  .sectionIntro-photo {
    margin-top: 24px; }
    @media (max-width: 759px) {
      .sectionIntro-photo {
        margin-top: 0; } }
    .sectionIntro-photo img {
      width: 100%;
      vertical-align: middle; }
  .sectionIntro-person {
    margin-bottom: 24px; }
    @media (min-width: 760px) {
      .sectionIntro-person {
        display: none; } }
    .sectionIntro-person img {
      display: block;
      width: 280px;
      margin: 0 auto; }

.sectionLandingMain {
  background-color: #fff;
  padding-bottom: 80px;
  margin-top: 48px; }
  @media (max-width: 759px) {
    .sectionLandingMain {
      margin-top: 24px;
      padding-bottom: 40px; } }
  .sectionLandingMain--news {
    position: relative; }
    .sectionLandingMain--news::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 280px;
      background-color: #f5f7fd; }
  .sectionLandingMain-titleHolder {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    margin: 48px 0; }
    @media (max-width: 759px) {
      .sectionLandingMain-titleHolder {
        display: block;
        margin: 24px 0; } }
  .sectionLandingMain-chain {
    flex: 1 1 auto;
    background-size: 10px 6px;
    background-image: url(assets/images/chain.png);
    height: 6px; }
    .sectionLandingMain-chain--left {
      background-position: right 2px center;
      /* ALSO border-right: 2px solid transparent; */ }
    .sectionLandingMain-chain--right {
      background-position: left; }
    @media (max-width: 759px) {
      .sectionLandingMain-chain {
        display: none; } }
  .sectionLandingMain-title {
    flex: 0 0 auto;
    font-family: "Merriweather", Palatino, Georgia, serif;
    font-weight: 700;
    letter-spacing: 0.8px;
    color: #23324c;
    line-height: 1.4;
    font-size: 36px;
    padding: 32px;
    background-color: #fff;
    position: relative;
    z-index: 1;
    display: inline-block;
    margin: 0; }
    @media (max-width: 759px) {
      .sectionLandingMain-title {
        font-size: 24px;
        padding: 16px; } }

.sectionLandingOverlay {
  background-color: #fff;
  padding: 24px 0;
  box-shadow: 0 0 20px 0 rgba(35, 50, 76, 0.1); }

.sectionMustKnow-cover {
  position: relative;
  background-color: #23324c;
  padding: 138px 0 176px; }
  @media (max-width: 759px) {
    .sectionMustKnow-cover {
      padding: 64px 24px; } }
  @media (max-width: 479px) {
    .sectionMustKnow-cover {
      padding: 64px 16px; } }

.sectionMustKnow-fadedBack {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  opacity: 0.3;
  background-position: center; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
    .sectionMustKnow-fadedBack {
      display: none; } }
  .sectionMustKnow-fadedBack--retina {
    display: none; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
      .sectionMustKnow-fadedBack--retina {
        display: block; } }
  .sectionMustKnow-fadedBack--secondary {
    opacity: 0.15; }

.sectionMustKnow-insert {
  position: relative;
  background-color: #f6fafe;
  padding: 42px 0; }
  @media (max-width: 959px) {
    .sectionMustKnow-insert {
      padding: 42px 24px; } }
  @media (max-width: 479px) {
    .sectionMustKnow-insert {
      padding: 24px 16px; } }

.sectionMustKnow-wrapper {
  max-width: 760px;
  margin-right: auto;
  margin-left: auto; }
  .sectionMustKnow-wrapper img {
    width: 100%; }

.sectionMustKnow-content {
  position: relative;
  text-align: center; }
  @media (max-width: 959px) {
    .sectionMustKnow-content {
      padding: 0 24px; } }
  @media (max-width: 479px) {
    .sectionMustKnow-content {
      padding: 0 16px; } }

.sectionMustKnow-title {
  font-family: "Merriweather", Palatino, Georgia, serif;
  font-weight: 700;
  letter-spacing: 0.8px;
  color: #23324c;
  line-height: 1.4;
  color: #fff;
  font-size: 60px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 32px; }
  @media (max-width: 759px) {
    .sectionMustKnow-title {
      font-size: 28px;
      margin-bottom: 16px; } }
  @media (max-width: 479px) {
    .sectionMustKnow-title {
      font-size: 24px;
      margin-bottom: 16px; } }

.sectionMustKnow-caption {
  color: #fff;
  font-size: 30px;
  line-height: 1.5;
  text-align: center;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 959px) {
    .sectionMustKnow-caption {
      padding: 0 56px; } }
  @media (max-width: 759px) {
    .sectionMustKnow-caption {
      padding: 0 16px;
      font-size: 20px; } }
  @media (max-width: 479px) {
    .sectionMustKnow-caption {
      padding: 0 8px;
      font-size: 18px; } }

.sectionMustKnow-header {
  font-family: "Merriweather", Palatino, Georgia, serif;
  font-weight: 700;
  letter-spacing: 0.8px;
  color: #23324c;
  line-height: 1.4;
  color: #1c1c1c;
  font-size: 27px;
  line-height: 2; }
  @media (max-width: 759px) {
    .sectionMustKnow-header {
      font-size: 22px; } }
  @media (max-width: 479px) {
    .sectionMustKnow-header {
      font-size: 18px;
      line-height: 1.5; } }

.sectionMustKnow-image {
  vertical-align: middle; }

.sectionMustKnow-list {
  margin: 0;
  padding: 0;
  font-size: 22px;
  list-style: none;
  margin-top: 16px; }
  @media (max-width: 479px) {
    .sectionMustKnow-list {
      font-size: 18px; } }
  .sectionMustKnow-list li {
    position: relative;
    padding-left: 48px; }
    @media (max-width: 479px) {
      .sectionMustKnow-list li {
        padding-left: 20px; } }
    .sectionMustKnow-list li::after {
      content: '';
      background-color: #1c1c1c;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 16px; }
      @media (max-width: 479px) {
        .sectionMustKnow-list li::after {
          padding-left: -24px; } }

.sectionMustKnow-cargoShip {
  position: relative;
  background-color: #23324c;
  padding: 56px 0; }
  @media (max-width: 479px) {
    .sectionMustKnow-cargoShip {
      padding: 32px 0; } }

.sectionMustKnow-text {
  font-family: "Merriweather", Palatino, Georgia, serif;
  font-weight: 700;
  letter-spacing: 0.8px;
  color: #23324c;
  line-height: 1.4;
  line-height: 2;
  font-size: 22px;
  color: #fff;
  text-align: left;
  max-width: 760px;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 759px) {
    .sectionMustKnow-text {
      font-size: 18px;
      line-height: 1.8; } }
  @media (max-width: 479px) {
    .sectionMustKnow-text {
      font-size: 18px;
      line-height: 1.6; } }

.sectionService-cover {
  position: relative;
  background-color: #23324c;
  padding: 120px 0 320px; }
  @media (max-width: 759px) {
    .sectionService-cover {
      padding: 40px 16px 220px; } }
  @media (max-width: 479px) {
    .sectionService-cover {
      padding: 40px 0 220px; } }

.sectionService-splashScreen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  opacity: 0.2;
  background-position: center; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
    .sectionService-splashScreen {
      display: none; } }
  .sectionService-splashScreen--retina {
    display: none; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
      .sectionService-splashScreen--retina {
        display: block; } }

.sectionService-coverTitle {
  font-family: "Merriweather", Palatino, Georgia, serif;
  font-weight: 700;
  letter-spacing: 0.8px;
  color: #23324c;
  line-height: 1.4;
  color: #fff;
  font-size: 48px;
  max-width: 1096px;
  margin: 0 auto;
  position: relative;
  text-align: center; }
  @media (max-width: 759px) {
    .sectionService-coverTitle {
      font-size: 30px;
      padding: 0 16px; } }
  @media (max-width: 479px) {
    .sectionService-coverTitle {
      font-size: 24px; } }

.sectionService-content {
  position: relative;
  z-index: 2;
  margin: -260px 0 64px; }
  @media (max-width: 759px) {
    .sectionService-content {
      margin-top: -190px; } }
  @media (max-width: 479px) {
    .sectionService-content {
      margin-top: -190px; } }

.sectionService-box {
  background-color: #fff;
  margin: 40px 0;
  padding: 48px 96px;
  box-shadow: 0 0 40px 0 rgba(20, 46, 76, 0.1);
  overflow: hidden;
  position: relative; }
  @media (max-width: 759px) {
    .sectionService-box {
      margin: 24px 0;
      padding: 0 16px; } }

.sectionService-title {
  font-family: "Merriweather", Palatino, Georgia, serif;
  font-weight: 700;
  letter-spacing: 0.8px;
  color: #23324c;
  line-height: 1.4;
  font-size: 28px; }
  @media (max-width: 759px) {
    .sectionService-title {
      font-size: 22px; } }

.sectionService-text {
  letter-spacing: 0.3px; }

.sectionServiceList {
  padding: 16px 0 8px; }
  .sectionServiceList-row {
    display: flex;
    flex-wrap: wrap;
    margin: -16px;
    margin-bottom: 8px; }
    @media (max-width: 759px) {
      .sectionServiceList-row {
        flex-direction: column; } }
  .sectionServiceList-col {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: stretch; }
    @media (max-width: 759px) {
      .sectionServiceList-col {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 8px; } }

.servicePreview {
  display: flex;
  align-items: stretch; }
  .servicePreview-cover {
    position: relative;
    background-color: #23324c;
    padding: 40px 40px;
    display: flex;
    align-items: center; }
    @media (max-width: 759px) {
      .servicePreview-cover {
        padding: 24px 40px; } }
    @media (max-width: 759px) {
      .servicePreview-cover {
        padding: 16px 16px; } }
  .servicePreview-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    opacity: 0.2;
    background-position: center;
    transition: all .1s ease-out; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
      .servicePreview-background {
        display: none; } }
    .servicePreview-background--retina {
      display: none; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
        .servicePreview-background--retina {
          display: block; } }
  .servicePreview-link {
    text-decoration: none;
    color: inherit; }
    .servicePreview-link::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
  .servicePreview-link .servicePreview-title {
    font-family: "Merriweather", Palatino, Georgia, serif;
    font-weight: 700;
    letter-spacing: 0.8px;
    color: #23324c;
    line-height: 1.4;
    color: #fff;
    font-size: 30px;
    letter-spacing: 0.6px;
    text-align: center;
    position: relative; }
    @media (max-width: 479px) {
      .servicePreview-link .servicePreview-title {
        font-size: 22px; } }

.sidebarItem-title {
  font-family: "Merriweather", Palatino, Georgia, serif;
  font-weight: 700;
  letter-spacing: 0.8px;
  color: #23324c;
  line-height: 1.4;
  font-size: 18px;
  margin-bottom: 32px; }
  @media (max-width: 759px) {
    .sidebarItem-title {
      text-align: center;
      font-size: 24px; } }

.svgContainer {
  display: inline-block;
  font-size: 0;
  line-height: 0;
  vertical-align: middle; }
  .svgContainer svg {
    fill: currentColor;
    color: inherit;
    display: block; }

.button {
  -webkit-appearance: none;
  text-decoration: none;
  background: none;
  border: none;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
  font-family: inherit;
  border-radius: 3px;
  border: 1px solid transparent;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.8px;
  font-size: inherit;
  font-weight: 600;
  line-height: 24px;
  padding: 16px 32px;
  user-select: none;
  box-sizing: border-box; }
  .button:focus {
    outline: none; }
  @media (max-width: 759px) {
    .button {
      font-size: 18px;
      line-height: 32px; } }
  @media (max-width: 479px) {
    .button {
      font-size: 16px;
      line-height: 20px; } }
  .button--primary {
    background-color: #9c2836;
    color: #fff;
    box-shadow: 0 4px 10px 0 rgba(156, 40, 54, 0.15);
    transition: all .1s ease-out;
    position: relative;
    text-transform: uppercase; }
    .button--primary:hover {
      background-color: #cd585a; }
    .button--primary:active {
      background-color: #8d102a;
      transform: translateY(1px); }
    .button--primary:focus {
      box-shadow: 0 0 0 4px rgba(156, 40, 54, 0.2); }
    .button--primary:disabled {
      opacity: 0.8; }
  .button--smaller {
    line-height: 24px;
    padding: 12px 32px;
    font-size: 14px; }
  .button--regular {
    border: 1px solid #23324c;
    color: #23324c;
    transition: all .1s ease-out;
    text-transform: none; }
    .button--regular:hover {
      background-color: #23324c;
      color: #fff; }
    .button--regular:focus {
      box-shadow: 0 0 0 4px rgba(35, 50, 76, 0.2); }
    .button--regular:disabled {
      opacity: 0.8; }
  .button--red {
    border: 1px solid #9c2836;
    color: #9c2836; }
    .button--red:hover {
      background-color: #cd585a;
      border-color: #cd585a; }
    .button--red:focus {
      box-shadow: 0 0 0 4px rgba(156, 40, 54, 0.2); }
    .button--red:disabled {
      opacity: 0.8; }
  .button--uppercase {
    text-transform: uppercase; }
  .button--full {
    width: 100%; }

.checkbox-wrapper {
  padding-top: 0; }

.checkbox-el {
  /*https://developer.yahoo.com/blogs/ydn/clip-hidden-content-better-accessibility-53456.html*/
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden; }
  body:hover .checkbox-el a,
  body:hover .checkbox-el input,
  body:hover .checkbox-el button {
    display: none !important; }

.checkbox-mark {
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  position: relative;
  cursor: pointer; }
  .checkbox-mark::after {
    content: "";
    width: 8px;
    height: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-5px, -4px) rotate(-45deg) scale(0);
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    opacity: 0; }

.checkbox-el:checked + .checkbox-mark {
  border-color: #23324c;
  background-color: #23324c; }
  .checkbox-el:checked + .checkbox-mark::after {
    transform: translate(-5px, -4px) rotate(-45deg) scale(1);
    opacity: 1; }

.checkbox-el:disabled + .checkbox-mark {
  cursor: default; }

.checkbox-label {
  margin-left: 8px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  user-select: none; }

.checkbox-el:disabled ~ .checkbox-label {
  color: #d9d9d9;
  cursor: default; }

.dialog {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  opacity: 1;
  z-index: 500;
  transition: all .2s ease-out .0s, visibility 0s linear 0s; }
  .dialog--closed {
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease-out .0s, visibility 0s linear .2s; }
  .dialog-overlay {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    overflow-y: auto;
    padding: 16px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8); }
  .dialog-window {
    background-color: #fff;
    border-radius: 4px;
    position: relative;
    flex: 0 0 auto;
    transform: scale(1);
    transform-origin: 50% 100%;
    transition: all .2s ease-out .0s;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 800px; }
  .dialog--closed .dialog-window {
    transform: scale(0.8); }
  .dialog-head {
    padding: 32px 32px 0; }
    @media (max-width: 759px) {
      .dialog-head {
        padding-left: 16px;
        padding-right: 16px; } }
  .dialog-title {
    font-family: "Merriweather", Palatino, Georgia, serif;
    font-weight: 700;
    letter-spacing: 0.8px;
    color: #23324c;
    line-height: 1.4;
    line-height: 1.4;
    margin: 0;
    text-align: center;
    font-size: 28px; }
    @media (max-width: 759px) {
      .dialog-title {
        font-size: 20px; } }
  .dialog-content {
    padding: 32px;
    overflow-x: hidden;
    overflow-y: auto; }
    @media (max-width: 759px) {
      .dialog-content {
        padding: 16px; } }
  .dialog-close {
    -webkit-appearance: none;
    background: none;
    padding: 0;
    width: 20px;
    height: 20px;
    padding: 0;
    display: inline-block;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    color: #545454;
    right: 12px;
    top: 12px; }
    .dialog-close:hover {
      color: #23324c; }
    .dialog-close:focus {
      outline: none; }
    .dialog-close::before {
      position: absolute;
      top: 50%;
      left: 0;
      content: "";
      width: 100%;
      height: 1px;
      transform-origin: 50% 50%;
      transform: rotate(-45deg);
      background-color: currentColor; }
    .dialog-close::after {
      position: absolute;
      top: 50%;
      left: 0;
      content: "";
      width: 100%;
      height: 1px;
      transform-origin: 50% 50%;
      transform: rotate(45deg);
      background-color: currentColor; }

.field {
  flex: 0 0 auto;
  padding: 8px;
  box-sizing: border-box; }
  @media (max-width: 759px) {
    .field {
      width: 100%; } }
  @media (max-width: 479px) {
    .field {
      width: 100%; } }
  .field--half {
    flex: 0 0 50%;
    max-width: 50%; }
    @media (max-width: 759px) {
      .field--half {
        flex: 0 0 100%;
        max-width: 100%; } }
  .field--full {
    flex: 0 0 100%;
    max-width: 100%; }
  .field-element {
    text-align: center; }

.form {
  overflow: visible; }
  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
    margin-bottom: 8px; }
    .form-row--actions {
      justify-content: center; }
    .form-row:last-child {
      margin-bottom: -8px; }

@media (min-width: 1024px) {
  .hidden--large {
    display: none; } }

@media (min-width: 760px) {
  .hidden--medium {
    display: none; } }

@media (max-width: 1023px) {
  .hidden--medium {
    display: none; } }

@media (max-width: 759px) {
  .hidden--small {
    display: none; } }

.input {
  position: relative;
  /*&-el {
    -webkit-appearance: none;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    line-height: 24px;
    padding: 12px 16px;
    width: 100%;
    box-sizing: border-box;
  
    &:focus {
      outline: none;
      border-color: $color-blue-whale;
      box-shadow: 0 0 0 4px rgba($color-blue-whale, 0.2);
    }
  }*/ }
  @media (max-width: 759px) {
    .input {
      font-size: 18px; } }
  .input-el {
    -webkit-appearance: none;
    border: 1px solid #d9d9d9;
    background-color: #fdfdfd;
    border-radius: 3px;
    line-height: 24px;
    padding: 12px 16px;
    width: 100%;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    transition: all .1s ease-out; }
    .input-el:focus {
      outline: none;
      border-color: #23324c;
      background-color: #fff; }

.phone {
  color: inherit;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle; }

.radio-wrapper {
  padding-top: 0; }

.radio-el {
  /*https://developer.yahoo.com/blogs/ydn/clip-hidden-content-better-accessibility-53456.html*/
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden; }
  body:hover .radio-el a,
  body:hover .radio-el input,
  body:hover .radio-el button {
    display: none !important; }

.radio-mark {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  position: relative;
  transition: all .1s ease-out;
  cursor: pointer; }
  .radio-mark::after {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 3px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-3px, -3px) scale(0);
    background-color: #23324c;
    opacity: 0;
    transition: all .2s ease-out; }

.radio-el:checked + .radio-mark {
  border-color: #23324c;
  border-width: 2px; }
  .radio-el:checked + .radio-mark::after {
    transform: translate(-3px, -3px) scale(1);
    opacity: 1; }

.radio-el:disabled + .radio-mark {
  cursor: default; }

.radio-label {
  margin-left: 8px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer; }

.radio-el:disabled ~ .radio-label {
  color: #d9d9d9;
  cursor: default; }

.select {
  position: relative; }
  @media (max-width: 759px) {
    .select {
      font-size: 18px; } }
  .select-el {
    -webkit-appearance: none;
    border: 1px solid #d9d9d9;
    background-color: #fdfdfd;
    border-radius: 3px;
    line-height: 24px;
    padding: 12px 16px;
    width: 100%;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    transition: all .1s ease-out; }
    .select-el:focus {
      outline: none;
      border-color: #23324c;
      background-color: #fff; }

.skype {
  color: inherit;
  text-decoration: none; }

.slider-container > div {
  display: inline-flex; }

.slider-item {
  height: 100%; }

.slider-nav {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0; }

.slider-paginationContainer {
  display: inline-flex;
  margin: 0 16px; }

.slider-paginationItem {
  -webkit-appearance: none;
  background: none;
  padding: 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin: 0 4px;
  border: solid 1px #23324c;
  cursor: pointer; }
  .slider-paginationItem--current {
    background-color: #23324c; }

.slider-next {
  -webkit-appearance: none;
  text-decoration: none;
  background: none;
  border: none;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
  font-family: inherit;
  cursor: pointer; }
  .slider-next:focus {
    outline: none; }

.slider-prev {
  -webkit-appearance: none;
  text-decoration: none;
  background: none;
  border: none;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
  font-family: inherit;
  cursor: pointer; }
  .slider-prev:focus {
    outline: none; }

.telegramLink {
  color: inherit;
  text-decoration: none; }

.textarea {
  position: relative; }
  @media (max-width: 759px) {
    .textarea {
      font-size: 18px; } }
  .textarea-el {
    -webkit-appearance: none;
    border: 1px solid #d9d9d9;
    background-color: #fdfdfd;
    border-radius: 3px;
    line-height: 24px;
    padding: 12px 16px;
    width: 100%;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    transition: all .1s ease-out; }
    .textarea-el:focus {
      outline: none;
      border-color: #23324c;
      background-color: #fff; }
  .textarea-el {
    resize: vertical; }

.viberLink {
  color: inherit;
  text-decoration: none; }

@media (max-width: 1023px) {
  .visible--large {
    display: none; } }

@media (max-width: 759px) {
  .visible--medium {
    display: none; } }

@media (min-width: 1024px) {
  .visible--medium {
    display: none; } }

@media (min-width: 760px) {
  .visible--small {
    display: none; } }

.whatsappLink {
  color: inherit;
  text-decoration: none; }

.reviewGrid {
  display: flex;
  flex-wrap: wrap;
  margin: -16px; }
  @media (max-width: 759px) {
    .reviewGrid {
      flex-direction: column; } }
  .reviewGrid-col {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 16px;
    box-sizing: border-box; }
    .reviewGrid-col--half {
      flex: 0 0 50%;
      max-width: 50%; }
    @media (max-width: 759px) {
      .reviewGrid-col {
        flex-basis: auto;
        max-width: none; } }
  .reviewGrid-label {
    text-align: right;
    font-size: 18px;
    padding: 16px 0; }
    @media (max-width: 759px) {
      .reviewGrid-label {
        font-size: 16px; } }

.notice {
  border: 3px dotted #9c2836;
  border-radius: 24px;
  padding: 16px;
  text-align: center; }
  @media (max-width: 759px) {
    .notice {
      border-width: 2px;
      border-radius: 16px;
      padding: 8px; } }
  .notice--contextServices {
    border-color: #fff; }
  .notice-text {
    font-size: 24px;
    font-weight: 700;
    color: #9c2836; }
    @media (max-width: 759px) {
      .notice-text {
        font-size: 18px; } }
  .notice--contextServices .notice-text {
    font-size: 20px;
    color: #fff; }

.whatWeDo {
  display: flex;
  margin: -16px; }
  @media (max-width: 759px) {
    .whatWeDo {
      flex-direction: column; } }
  .whatWeDo-col {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 16px;
    box-sizing: border-box; }
    @media (max-width: 759px) {
      .whatWeDo-col {
        flex: 0 0 auto;
        max-width: none; } }
  .whatWeDo-item {
    text-align: center; }
  .whatWeDo-pictureHolder {
    width: 60px;
    height: 60px;
    color: #23324c;
    display: inline-block;
    padding: 16px;
    border-radius: 46px;
    border: 3px dotted #23324c; }
  .whatWeDo-picture {
    width: 60px;
    height: 60px; }
  .whatWeDo-description {
    margin-top: 24px;
    font-size: 24px;
    font-weight: 700; }
    @media (max-width: 759px) {
      .whatWeDo-description {
        font-size: inherit; } }

.headerButton {
  -webkit-appearance: none;
  text-decoration: none;
  background: none;
  border: none;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
  font-family: inherit;
  border-radius: 3px;
  border: 1px solid transparent;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.8px;
  font-size: inherit;
  font-weight: 600;
  line-height: 24px;
  padding: 16px 32px;
  user-select: none;
  box-sizing: border-box; }
  .headerButton:focus {
    outline: none; }
  @media (max-width: 759px) {
    .headerButton {
      font-size: 18px;
      line-height: 32px; } }
  @media (max-width: 479px) {
    .headerButton {
      font-size: 16px;
      line-height: 20px; } }
  .headerButton--primary {
    background-color: #9c2836;
    color: #fff;
    box-shadow: 0 4px 10px 0 rgba(156, 40, 54, 0.15);
    transition: all .1s ease-out;
    position: relative;
    text-transform: uppercase; }
    .headerButton--primary:hover {
      background-color: #cd585a; }
    .headerButton--primary:active {
      background-color: #8d102a;
      transform: translateY(1px); }
    .headerButton--primary:focus {
      box-shadow: 0 0 0 4px rgba(156, 40, 54, 0.2); }
    .headerButton--primary:disabled {
      opacity: 0.8; }
  .headerButton--smaller {
    line-height: 24px;
    padding: 12px 32px;
    font-size: 14px; }
  .headerButton--regular {
    border: 1px solid #23324c;
    color: #23324c;
    transition: all .1s ease-out;
    text-transform: none; }
    .headerButton--regular:hover {
      background-color: #23324c;
      color: #fff; }
    .headerButton--regular:focus {
      box-shadow: 0 0 0 4px rgba(35, 50, 76, 0.2); }
    .headerButton--regular:disabled {
      opacity: 0.8; }
  .headerButton--red {
    border: 1px solid #9c2836;
    color: #9c2836; }
    .headerButton--red:hover {
      background-color: #cd585a;
      border-color: #cd585a; }
    .headerButton--red:focus {
      box-shadow: 0 0 0 4px rgba(156, 40, 54, 0.2); }
    .headerButton--red:disabled {
      opacity: 0.8; }
  .headerButton--uppercase {
    text-transform: uppercase; }
  .headerButton--full {
    width: 100%; }
  @media (max-width: 479px) {
    .headerButton {
      line-height: 20px;
      padding: 10px;
      font-size: 12px; } }

.header {
  padding: 16px 0 8px; }
  .header:after {
    content: "";
    background-size: 10px 6px;
    background-image: url(assets/images/chain.png);
    background-position: center;
    height: 6px;
    display: block;
    margin-top: 16px; }
  .header-container {
    display: flex;
    align-items: center;
    margin: 0 -8px; }
    @media (max-width: 759px) {
      .header-container {
        justify-content: space-between; } }
  .header-col {
    padding: 0 8px; }
    .header-col--logo {
      margin-right: auto; }
      @media (max-width: 759px) {
        .header-col--logo {
          display: none;
          flex: 0 1 0;
          width: 0;
          overflow: hidden; } }
  .header-messengers {
    text-align: right; }
    @media (max-width: 759px) {
      .header-messengers {
        text-align: left; } }
  .header-messengerIcon {
    width: 26px;
    height: 26px; }
    @media (max-width: 759px) {
      .header-messengerIcon {
        width: 24px;
        height: 24px; } }

.headerLogo {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  cursor: pointer; }
  .headerLogo img {
    vertical-align: middle;
    width: 203px; }
  .headerLogo--responsive img {
    width: 100%; }

.headerPhone {
  text-decoration: none;
  color: inherit;
  display: block;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.4px; }
  @media (max-width: 479px) {
    .headerPhone {
      font-size: 14px; } }
