.sectionContactsForm {
  background-color: $color-white;
  padding: 48px 0;

  @include media("<small") {
    padding: 24px 0;
  }

  &-title {
    @include heading();
    
    color: $color-primary;
    font-size: 36px;
    text-align: center;
    margin: 0 auto;
    max-width: 870px;

    @include media("<medium") {
      font-size: 30px;
    }

    @include media("<small") {
      font-size: 20px;
    }
  }

  &-subtitle {
    text-align: center;
    font-size: 20px;
    margin-bottom: 36px;

    @include media("<medium") {
      font-size: inherit;
      margin-bottom: 16px;
    }
  }

  &-content {
    max-width: 768px;
    margin: 0 auto;
  }

  &-box {
    box-sizing: border-box;
    padding: 40px 0;
    background-color: $color-white;
    box-shadow: 0 0 40px 0 rgba(#23324c, 0.1);

    @include media("<xmedium") {
      padding: 40px 24px;
    }

    @include media("<small") {
      padding: 32px 16px;
    }
  }
}