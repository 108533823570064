@mixin input() {
  position: relative;



  @include media("<medium") {
    font-size: 18px;
  }

  &-el {
    -webkit-appearance: none;
    border: 1px solid $color-gainsboro;
    background-color: $color-almost-white;
    border-radius: 3px;
    line-height: 24px;
    padding: 12px 16px;
    width: 100%;
    box-sizing: border-box;
    
    text-overflow: ellipsis;
    overflow: hidden;

    position: relative;
    
    transition: all .1s ease-out;

    &:focus {
      outline: none;
      border-color: $color-blue-whale;
      //box-shadow: 0 0 0 4px rgba($color-blue-whale, 0.1);
      background-color: $color-white;
    }
  }
}