.newsPreview {
  position: relative;
  box-shadow: 0 4px 40px 0 rgba(35, 50, 76, 0.1);
  transition: all .1s ease-out;
  
  &:hover {
    transform: translateY(2px);
    box-shadow: 0 0 20px 0 rgba(35, 50, 76, 0.15);
  }

  &-photoHolder {
    height: 220px;
    overflow: hidden;
    background-color: $color-blue-whale;

    @include media("<large") {
      height: auto;
    }
  }
  
  &-photo {
    vertical-align: middle;
    width: 100%;
  }
  
  &-detailsHolder {
    height: 220px;
    padding: 32px 32px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    background-color: $color-white;

    @include media("<medium") {
      padding: 16px;
      height: auto;
    }
  }
  
  &-title {
    font-weight: 700;
    font-size: 18px;
    margin: 0;
    line-height: 1.6;

    @include media("<medium") {
       font-size: 20px;
     }

    @include media("<small") {
      font-size: 18px;
    }
  }
  
  &-link {
    flex: 1 1 auto;
    text-decoration: none;
    color: inherit;
    
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  
  &-date {
    flex: 0 0 auto;
    color: $color-grey;
    margin-top: 16px;
  }
}