.landingQuote {
  position: relative;
  border: 3px dotted $color-blue-whale;
  border-radius: 24px;
  margin-top: 16px;

  @include media("<medium") {
    border-width: 2px;
    border-radius: 16px;
  }
  
  &::before {
    content: "";
    background-image: url("assets/images/quote.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-color: $color-white;
    position: absolute;
    top: 0;
    margin-top: -18px;
    width: 48px;
    height: 38px;
    left: 48px;
    padding: 0 24px;

    @include media("<medium") {
      background-size: contain;
      width: 24px;
      height: 19px;
      left: 16px;
      margin-top: -10px;
      padding: 0 4px;
    }
  }

  &::after {
    content: "";
    background-image: url("assets/images/quote-flip.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-color: $color-white;
    position: absolute;
    bottom: 0;
    margin-bottom: -18px;
    width: 48px;
    height: 38px;
    right: 48px;
    padding: 0 24px;

    @include media("<medium") {
      background-size: contain;
      width: 24px;
      height: 19px;
      right: 16px;
      margin-bottom: -10px;
      padding: 0 4px;
    }
  }

  &--bgLavender {
    &::before {
      background-color: $color-lavender;
    }
    
    &::after {
      background-color: $color-lavender;
    }
  }

  &-titleHolder {
    display: block;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 0;
  }
  
  &-title {
    @include heading();
    font-size: 30px;
    line-height: 30px;
    position: relative;
    top: -15px;
    padding: 0 32px;
    display: inline-block;
    background-color: $color-white;

    @include media("<medium") {
      font-size: 22px;
      padding: 0 8px;
    }

    @include media("<small") {
      font-size: 18px;
    }
  }

  &--bgLavender &-title {
    background-color: $color-lavender;
  }

  &--bigger &-title {
    font-size: 36px;

    @include media("<medium") {
      font-size: 22px;
    }

    @include media("<small") {
      font-size: 18px;
    }
   }
  
  &-text {
    font-size: 20px;
    padding: 36px 36px 24px;

    @include media("<medium") {
      font-size: inherit;
      padding: 24px 16px 16px;
    }

    @include media("<small") {
      font-size: 16px;
    }
  }
  
  &--bigger &-text {
    font-weight: 600;
  }
}