.article {
  color: $color-nero;

  &-title {
    @include heading();
    font-size: 30px;

    @include media("<small") {
      font-size: 24px;
    }
  }

  &-date {
    color: $color-grey;
    margin: 24px 0 22px;
    display: inline-block;
  }

  &-content {
    blockquote {
      margin: 0 -32px;
      padding: 32px;
      background-color: $color-denim;
      font-size: 22px;
      letter-spacing: 0.3px;
      color: $color-blue-whale;
      font-weight: 700;

      @include media("<small") {
        font-size: 18px;
        margin: 0;
        padding: 16px;
      }
    }

    img {
      width: 100%;
    }
  }
}