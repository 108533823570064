$font-open-sans: 'Open Sans', 'Gill Sans', 'Gill Sans MT', Verdana, sans-serif;

@mixin font-open-sans-300() {
    @font-face {
        font-family: 'Open Sans';
        src: local('Open Sans Light'), local('OpenSans-Light'),
            url('assets/fonts/Open Sans/OpenSans-Light.woff2') format('woff2'),
            url('assets/fonts/Open Sans/OpenSans-Light.woff') format('woff');
        font-weight: 300;
        font-style: normal;
    }
}

@mixin font-open-sans-300-i() {
    @font-face {
        font-family: 'Open Sans';
        src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
            url('assets/fonts/Open Sans/OpenSans-LightItalic.woff2') format('woff2'),
            url('assets/fonts/Open Sans/OpenSans-LightItalic.woff') format('woff');
        font-weight: 300;
        font-style: italic;
    }
}

@mixin font-open-sans-400() {
    @font-face {
        font-family: 'Open Sans';
        src: local('Open Sans Regular'), local('OpenSans-Regular'),
            url('assets/fonts/Open Sans/OpenSans-Regular.woff2') format('woff2'),
            url('assets/fonts/Open Sans/OpenSans-Regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
}

@mixin font-open-sans-400-i() {
    @font-face {
        font-family: 'Open Sans';
        src: local('Open Sans Italic'), local('OpenSans-Italic'),
            url('assets/fonts/Open Sans/OpenSans-Italic.woff2') format('woff2'),
            url('assets/fonts/Open Sans/OpenSans-Italic.woff') format('woff');
        font-weight: normal;
        font-style: italic;
    }
}

@mixin font-open-sans-600-i() {
    @font-face {
        font-family: 'Open Sans';
        src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
            url('assets/fonts/Open Sans/OpenSans-SemiBoldItalic.woff2') format('woff2'),
            url('assets/fonts/Open Sans/OpenSans-SemiBoldItalic.woff') format('woff');
        font-weight: 600;
        font-style: italic;
    }
}

@mixin font-open-sans-600() {
    @font-face {
        font-family: 'Open Sans';
        src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
            url('assets/fonts/Open Sans/OpenSans-SemiBold.woff2') format('woff2'),
            url('assets/fonts/Open Sans/OpenSans-SemiBold.woff') format('woff');
        font-weight: 600;
        font-style: normal;
    }
}

@mixin font-open-sans-700() {
    @font-face {
        font-family: 'Open Sans';
        src: local('Open Sans Bold'), local('OpenSans-Bold'),
            url('assets/fonts/Open Sans/OpenSans-Bold.woff2') format('woff2'),
            url('assets/fonts/Open Sans/OpenSans-Bold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
    }
}

@mixin font-open-sans-700-i() {
    @font-face {
        font-family: 'Open Sans';
        src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
            url('assets/fonts/Open Sans/OpenSans-BoldItalic.woff2') format('woff2'),
            url('assets/fonts/Open Sans/OpenSans-BoldItalic.woff') format('woff');
        font-weight: bold;
        font-style: italic;
    }
}

@mixin font-open-sans-800() {
    @font-face {
        font-family: 'Open Sans';
        src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
            url('assets/fonts/Open Sans/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
            url('assets/fonts/Open Sans/OpenSans-ExtraBoldItalic.woff') format('woff');
        font-weight: 800;
        font-style: italic;
    }
}

@mixin font-open-sans-800() {
    @font-face {
        font-family: 'Open Sans';
        src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
            url('assets/fonts/Open Sans/OpenSans-ExtraBold.woff2') format('woff2'),
            url('assets/fonts/Open Sans/OpenSans-ExtraBold.woff') format('woff');
        font-weight: 800;
        font-style: normal;
    }
}