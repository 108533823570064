.page {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  
  &--noScroll {
  }
  
  &-header {
    
  }

  &--headOverlay &-header {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
  
  &-content {
    flex: 1 0 auto;
  }
  
  &-footer {
    
  }
}