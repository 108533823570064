.reviewGrid {
  display: flex;
  flex-wrap: wrap;
  margin: -16px;

  @include media("<medium") {
    flex-direction: column;
  }
  
  &-col {
    flex: 0 0 percentage(1);
    max-width: percentage(1);
    padding: 16px;
    box-sizing: border-box;
    
    &--half {
      flex: 0 0 percentage(1/2);
      max-width: percentage(1/2);
    }
    
    @include media("<medium") {
      flex-basis: auto;
      max-width: none;
    }
  }

  &-label {
    text-align: right;
    font-size: 18px;
    padding: 16px 0;

    @include media("<medium") {
      font-size: 16px;
    }
  }
}