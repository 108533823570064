$font-merriweather: 'Merriweather', Palatino, Georgia, serif;

@mixin font-merriweather-300() {
    @font-face {
        font-family: 'Merriweather';
        src: local('Merriweather Light'), local('Merriweather-Light'),
            url('assets/fonts/Merriweather/Merriweather-Light.woff2') format('woff2'),
            url('assets/fonts/Merriweather/Merriweather-Light.woff') format('woff');
        font-weight: 300;
        font-style: normal;
    }
}

@mixin font-merriweather-300-i() {
    @font-face {
        font-family: 'Merriweather';
        src: local('Merriweather Light Italic'), local('Merriweather-LightItalic'),
            url('assets/fonts/Merriweather/Merriweather-LightItalic.woff2') format('woff2'),
            url('assets/fonts/Merriweather/Merriweather-LightItalic.woff') format('woff');
        font-weight: 300;
        font-style: italic;
    }
}

@mixin font-merriweather-400-i() {
    @font-face {
        font-family: 'Merriweather';
        src: local('Merriweather Italic'), local('Merriweather-Italic'),
            url('assets/fonts/Merriweather/Merriweather-Italic.woff2') format('woff2'),
            url('assets/fonts/Merriweather/Merriweather-Italic.woff') format('woff');
        font-weight: normal;
        font-style: italic;
    }
}

@mixin font-merriweather-400() {
    @font-face {
        font-family: 'Merriweather';
        src: local('Merriweather'), local('Merriweather-Regular'),
            url('assets/fonts/Merriweather/Merriweather-Regular.woff2') format('woff2'),
            url('assets/fonts/Merriweather/Merriweather-Regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
}

@mixin font-merriweather-700-i() {
    @font-face {
        font-family: 'Merriweather';
        src: local('Merriweather Bold Italic'), local('Merriweather-BoldItalic'),
            url('assets/fonts/Merriweather/Merriweather-BoldItalic.woff2') format('woff2'),
            url('assets/fonts/Merriweather/Merriweather-BoldItalic.woff') format('woff');
        font-weight: bold;
        font-style: italic;
    }
}

@mixin font-merriweather-700() {
    @font-face {
        font-family: 'Merriweather';
        src: local('Merriweather Bold'), local('Merriweather-Bold'),
            url('assets/fonts/Merriweather/Merriweather-Bold.woff2') format('woff2'),
            url('assets/fonts/Merriweather/Merriweather-Bold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
    }
}

@mixin font-merriweather-900() {
    @font-face {
        font-family: 'Merriweather';
        src: local('Merriweather Black'), local('Merriweather-Black'),
            url('assets/fonts/Merriweather/Merriweather-Black.woff2') format('woff2'),
            url('assets/fonts/Merriweather/Merriweather-Black.woff') format('woff');
        font-weight: 900;
        font-style: normal;
    }
}

@mixin font-merriweather-900-i() {
    @font-face {
        font-family: 'Merriweather';
        src: local('Merriweather Black Italic'), local('Merriweather-BlackItalic'),
            url('assets/fonts/Merriweather/Merriweather-BlackItalic.woff2') format('woff2'),
            url('assets/fonts/Merriweather/Merriweather-BlackItalic.woff') format('woff');
        font-weight: 900;
        font-style: italic;
    }
}