.slider {
  $slider-paginationItem-border-color: $color-blue-whale;
  $slider-current-bg-color: $color-blue-whale;

  &-container {
    &>div {
      display: inline-flex;
    }
  }

  &-item {
    height: 100%;
  }

  &-nav {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
  }

  &-paginationContainer {
    display: inline-flex;
    margin: 0 16px;
  }

  &-paginationItem {
    -webkit-appearance: none;
    background: none;
    padding: 0;

    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin: 0 4px;
    border: solid 1px $slider-paginationItem-border-color;
    cursor: pointer;

    &--current {
      background-color: $slider-current-bg-color;
    }
  }

  &-next {
    @include buttonReset();
    cursor: pointer;
  }

  &-prev {
    @include buttonReset();
    cursor: pointer;
  }

}