.providedServices {


  &-slide {
    position: relative;
    height: 100%;
  }

  &-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;

    @include media("retina2x") {
      display: none;
    }

    &--retina {
      display: none;

      @include media("retina2x") {
        display: block;
      }
    }
  }

  &-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(90deg, rgba($color-blue-whale, 0.9), rgba($color-blue-whale, 0.7) 60%, rgba($color-blue-whale, 0.2));
  }

  &-content {
    position: relative;
    z-index: 2;

    padding: 40px 0;
    max-width: 600px;
  }

  &-title {
    @include heading();
    font-size: 30px;
    line-height: 1.5;
    color: $color-white;
    margin: 0;

    @include media("<medium") {
      font-size: 20px;
    }
}
  
  &-text {
    font-size: 20px;
    margin: 32px 0;
    color: $color-white;

    @include media("<medium") {
      font-size: inherit;
    }
  }

  &-moreHolder {
    text-align: center;
    margin-top: 36px;
  }

  &-more {
    @include linkReset();
    @include button();

    position: relative;
  }
}