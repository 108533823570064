.sectionContacts {
  &-title {
    @include heading();
    font-size: 36px;
    color: $color-primary;
    text-align: center;
    max-width: 800px;
    margin: 60px 0;
    margin-left: auto;
    margin-right: auto;

    @include media("<medium") {
      font-size: 24px;
      margin: 24px 0;
    }
  }
  
  &-details {
    display: flex;
    margin: -16px;

    @include media("<medium") {
      flex-direction: column;
      margin-bottom: 24px;
    }
  }
  
  &-col {
    flex: 0 0 percentage(1/3);
    max-width: percentage(1/3);
    text-align: center;
    padding: 16px;
    box-sizing: border-box;

    @include media("<medium") {
      flex: 0 0 auto;
      max-width: none;
    }
  }
  
  &-itemIcon {
    display: inline-block;
    width: 28px;
    height: 36px;
  }
  
  &-itemContent {
    
  }
  
  &-map {
    margin-top: 60px;
    height: 480px;
  }
  
  &-additionIcon {
    width: 26px;
    height: 26px;
  }
}