.sectionIntro {
  background-color: $color-lavender;
  overflow: hidden;
  
  &-container {
    display: flex;
    margin: -16px;

    @include media("<medium") {
      flex-direction: column;
    }
  }
  
  &-col {
    flex: 1 1 auto;
    box-sizing: border-box;
    padding: 16px;
    
    &--quote {
      
    }
    
    &--photo {
      flex: 0 0 420px;
      min-width: 420px;
      box-sizing: border-box;
      align-self: flex-end;

      @include media("<medium") {
        min-width: auto;

        display: none;
      }
    }

    @include media("<medium") {
      flex: 0 0 auto;
    }
  }
  
  &-quote {
    margin-bottom: 32px;

    @include media("<medium") {
      margin-bottom: 16px;
    }
  }
  
  &-subtitle {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.4px;
    text-align: center;
    margin-top: 60px;
    padding: 16px 0;

    @include media("<medium") {
      font-size: 20px;
      margin-top: 24px;
    }

    @include media("<small") {
      font-size: 18px;
    }
  }
  
  &-photo {
    margin-top: 24px;

    @include media("<medium") {
      margin-top: 0;
    }
    
    img {
      width: 100%;
      vertical-align: middle;
    }
  }

  &-person {
    margin-bottom: 24px;

    @include media(">=medium") {
      display: none;
    }

    img {
      display: block;
      width: 280px;
      margin: 0 auto;
    }
  }
}