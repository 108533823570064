.sectionService {

  &-cover {
    position: relative;
    background-color: $color-blue-whale; // цвет покрытия
    padding: 120px 0 320px;

    @include media("<medium") {
      padding: 40px 16px 220px;
    }

    @include media("<small") {
      padding: 40px 0 220px;
    }
  }

  &-splashScreen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    opacity: 0.2;
    background-position: center;

    @include media("retina2x") {
      display: none;
    }

    &--retina {
      display: none;

      @include media("retina2x") {
        display: block;
      }
    }
  }

  &-coverTitle {
    @include heading();
    color: $color-text-inverse;
    font-size: 48px;
    max-width: 1096px;
    margin: 0 auto;
    position: relative;
    text-align: center;

    @include media("<medium") {
      font-size: 30px;
      padding: 0 16px;
    }

    @include media("<small") {
      font-size: 24px;
    }
  }

  &-content {
    position: relative;
    z-index: 2;
    margin: -260px 0 64px;

    @include media("<medium") {
      margin-top: -190px;
    }

    @include media("<small") {
      margin-top: -190px;
    }
  }

  &-box {
    background-color: $color-white;
    margin: 40px 0;
    padding: 48px 96px;
    box-shadow: 0 0 40px 0 rgba(20, 46, 76, 0.1);
    overflow: hidden;
    position: relative;

    @include media("<medium") {
      margin: 24px 0;
      padding: 0 16px;
    }
  }

  &-title {
    @include heading();
    font-size: 28px;

    @include media("<medium") {
      font-size: 22px;
    }
  }

  &-text {
    letter-spacing: 0.3px;
  }
}