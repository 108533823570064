.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  background-color: $color-white-smoke;

  @include media("<medium") {
    flex-direction: column;
  }
  
  &-link {
    @include linkReset();
    font-size: 14px;
    text-transform: uppercase;
    padding: 8px 16px;
    cursor: pointer;
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  &-logo {
    padding: 0 16px;

    @include media("<medium") {
      order: -1;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}