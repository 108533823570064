.sectionLandingMain {
  background-color: $color-white;
  padding-bottom: 80px;
  margin-top: 48px;

  @include media("<medium") {
    margin-top: 24px;
    padding-bottom: 40px;
  }

  &--news {
    position: relative;
    
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 280px;
      background-color: $color-lavender;
    }
  }
  
  &-titleHolder {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    margin: 48px 0;

    @include media("<medium") {
      display: block;
      margin: 24px 0;
    }
  }
  
  &-chain {
    flex: 1 1 auto;
    background-size: 10px 6px;
    background-image: url(assets/images/chain.png);
    height: 6px;
    
    &--left {
      background-position: right 2px center;
      /* ALSO border-right: 2px solid transparent; */
    }
    
    &--right {
      background-position: left;
    }

    @include media("<medium") {
      display: none;
    }
  }
  
  &-title {
    flex: 0 0 auto;
    @include heading();
    font-size: 36px;
    padding: 32px;
    background-color: $color-white;
    position: relative;
    z-index: 1;
    display: inline-block;
    margin: 0;

    @include media("<medium") {
      font-size: 24px;
      padding: 16px;
    }
  }
  
  &-content {
    
  }
}