.field {
  flex: 0 0 auto;
  padding: 8px;
  box-sizing: border-box;

  @include media("<medium") {
    width: 100%;
  }

  @include media("<small") {
    width: 100%;
  }
  
  &--half {
    flex: 0 0 percentage(1/2);
    max-width: percentage(1/2);

    @include media("<medium") {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  
  &--full {
    flex: 0 0 percentage(1);
    max-width: percentage(1);
  }

  &-element {
    text-align: center;
  }
}