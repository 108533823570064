.articleGrid {
  display: flex;
  margin: -32px;

  @include media("<medium") {
    justify-content: center;
    flex-direction: column;
  }

  &-main {
    flex: 1 1 auto;
    padding: 32px;
    box-sizing: border-box;

    @include media("<medium") {
      flex: 0 0 auto;
    }
  }

  &-side {
    flex: 0 0 360px;
    max-width: 360px;
    padding: 32px;
    box-sizing: border-box;

    @include media("<medium") {
      flex: 0 0 auto;
      max-width: 100%;
    }
  }
}
