@mixin buttonReset() {
  -webkit-appearance: none;
  text-decoration: none;
  background: none;
  border: none;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
  font-family: inherit;

  &:focus {
    outline: none;
  }
}

@mixin button() {
  border-radius: 3px;
  border: 1px solid transparent;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.8px;
  font-size: inherit;
  font-weight: 600;
  line-height: 24px;
  padding: 16px 32px;
  user-select: none;
  box-sizing: border-box;

  @include media("<medium") {
    font-size: 18px;
    line-height: 32px;
  }

  @include media("<small") {
    font-size: 16px;
    line-height: 20px;
  }
    
  &--primary {
    background-color: $color-primary;
    color: $color-text-inverse;
    box-shadow: 0 4px 10px 0 rgba(#9C2836, 0.15);
    transition: all .1s ease-out;
    position: relative;
    text-transform: uppercase;

    &:hover {
      background-color: $color-indian-red;
    }

    &:active {
      background-color: #8d102a;
      transform: translateY(1px);
    }

    &:focus {
      box-shadow: 0 0 0 4px rgba($color-primary, 0.2);
    }
    
    &:disabled {
      opacity: 0.8
    }
  }
  
  &--smaller {
    line-height: 24px;
    padding: 12px 32px;
    font-size: 14px;
  }
  
  &--regular {
    border: 1px solid $color-blue-whale;
    color: $color-blue-whale;
    transition: all .1s ease-out;
    text-transform: none;
    
    &:hover {
      background-color: $color-blue-whale;
      color: $color-text-inverse;
    }
    
    &:active {
      
    }

    &:focus {
      box-shadow: 0 0 0 4px rgba($color-blue-whale, 0.2);
    }

    &:disabled {
      opacity: 0.8
    }
  }

  &--red {
    border: 1px solid $color-primary;
    color: $color-primary;

    &:hover {
      background-color: $color-indian-red;
      border-color: $color-indian-red;
    }

    &:active {

    }

    &:focus {
      box-shadow: 0 0 0 4px rgba($color-primary, 0.2);
    }

    &:disabled {
      opacity: 0.8
    }
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--full {
    width: 100%;
  }
}