.svgContainer {
  display: inline-block;
  font-size: 0;
  line-height: 0;
  vertical-align: middle;

  svg {
    fill: currentColor;
    color: inherit;
    display: block;
  }
}