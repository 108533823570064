.sectionError {
  text-align: center;
  background-image: url(assets/images/404-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;

  &-wrapper {
    flex: 0 0 auto;
  }

  &-image {
    vertical-align: middle;

    @include media("<medium") {
      width: 100%;
    }
  }

  &-text {
    @include heading();
    font-size: 40px;
    color: #000;
    margin: 0;

    @include media("<medium") {
      font-size: 32px;
    }
  }

  &-buttonHolder {
    margin-top: 24px;
    text-align: center;
  }

  &-button {
    @include linkReset();
    @include button();
  }
}