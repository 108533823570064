.heroCounter {
  display: flex;
  align-items: flex-start;
  
  &-col {
    flex: 1 1 auto;
    text-align: center;
    padding: 0 8px;
    
    &--sep {
      flex: 0 0 auto;
      width: 1px;
      background-color: rgba($color-white, 0.8);
      align-self: stretch;
      padding: 0;
    }
  }
  
  &-caption {
    color: $color-white;
    font-family: $font-merriweather;
    font-size: 22px;

    @include media("<medium") {
      font-family: $font-open-sans;
      font-size: 16px;
    }

    @include media("<small") {
      font-size: 14px;
    }
  }
  
  &-number {
    color: $color-white;
    font-family: $font-merriweather;
    font-size: 76px;
    font-weight: 700;
    line-height: 1.2;

    @include media("<medium") {
      font-size: 26px;
    }

    @include media("<small") {
      font-size: 22px;
    }
  }
}