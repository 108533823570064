.sectionContactsDetailed {
  &-row {
    display: flex;
    margin: 0 -16px;
    align-items: flex-start;

    @include media("<xmedium") {
      flex-direction: column;
      align-items: center;
    }
  }

  &-col {
    padding: 0 16px;
    font-size: 20px;
    box-sizing: border-box;

    @include media("<xmedium") {
      align-self: stretch;
    }

    &--map {
      flex: 0 0 percentage(2/3);
      max-width: percentage(2/3);

      @include media("<xmedium") {
        flex-basis: auto;
        max-width: none;
      }

      @include media("<medium") {
        order: 1;
      }
    }

    &--content {
      flex: 0 0 percentage(1/3);
      max-width: percentage(1/3);

      @include media("<xmedium") {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-basis: auto;
        max-width: none;
      }

      @include media("<medium") {
        padding-bottom: 32px;
      }
    }
  }

  &-mapWrapper {
    width: 100%;
    height: 340px;

    @include media("<small") {
      text-align: center;
      height: 200px;
    }
  }

  &-title {
    @include heading();
    color: $color-brown;
    font-size: 22px;
    letter-spacing: 0.2px;
    margin-bottom: 24px;
    margin-top: 0;

    @include media("<xmedium") {
      text-align: center;
      flex: 0 0 100%;
      margin-top: 16px;
    }
  }

  &-rowItem {
    display: flex;
    margin: 0 -16px;
    align-items: flex-start;
    padding-bottom: 16px;

    @include media("<xmedium") {
      flex: 0 0 percentage(1/3);
      max-width: percentage(1/3);
      flex-direction: column;
      justify-content: flex-start;
      margin: 0;
    }

    @include media("<medium") {
      flex: 0 0 100%;
      max-width: none;
      flex-direction: row;
      align-content: center;
      margin: 8px -16px;
    }

    @include media("<small") {
      margin: 8px -6px;
    }
  }

  &-colItem {
    padding: 0 16px;
    font-size: 20px;
    box-sizing: border-box;

    @include media("<xmedium") {
      padding: 0;
      align-self: center;
      text-align: center;
    }

    @include media("<medium") {
      padding: 0 16px;
      text-align: left;
    }

    @include media("<small") {
      align-self: flex-start;
      padding: 0 6px;
      font-size: 16px;
    }
  }

  &-itemIcon {
    display: inline-block;
    width: 20px;
    height: 26px;
    padding-top: 8px;

    @include media("<xmedium") {
      padding: 0;
    }

    @include media("<medium") {
      margin: 0;
    }
  }

  &-additionIcon {
    width: 26px;
    height: 26px;
    display: inline-block;
    vertical-align: middle;
    //color: $color-primary;
  }
}
