.newsList {

  &-all {
    display: flex;
    margin: -16px;
    flex-wrap: wrap;
  }
  
  &-item {
    flex: 0 0 percentage(1/3);
    max-width: percentage(1/3);
    box-sizing: border-box;
    padding: 16px;

    @include media("<large") {
      flex: 0 0 percentage(1/2);
      max-width: percentage(1/2);
    }

    @include media("<medium") {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &-moreHolder {
    text-align: center;
    margin-top: 36px;
  }

  &-more {
    @include linkReset();
    @include button();
    
    position: relative;
  }

}