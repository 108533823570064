.radio {

  &-wrapper {
    padding-top: 0;
  }

  &-el {
    @include visually-hidden();
  }

  &-mark {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 1px solid $color-frame;
    box-sizing: border-box;
    position: relative;
    transition: all .1s ease-out;
    cursor: pointer;

    &::after {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 3px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-3px, -3px) scale(0);
      background-color: $color-blue-whale;
      opacity: 0;
      transition: all .2s ease-out;
    }
  }

  &-el:checked + &-mark {
    border-color: $color-blue-whale;
    border-width: 2px;

    &::after {
      transform: translate(-3px, -3px) scale(1);
      opacity: 1;
    }
  }

  &-el:disabled + &-mark {
    cursor: default;
  }

  &-label {
    margin-left: 8px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }

  &-el:disabled ~ &-label {
    color: $color-frame;
    cursor: default;
  }
}