.notice {
  border: 3px dotted $color-primary;
  border-radius: 24px;
  padding: 16px;
  text-align: center;

  @include media("<medium") {
    border-width: 2px;
    border-radius: 16px;
    padding: 8px;
  }

  &--contextServices {
    border-color: $color-white;
  }

  &-text {
    font-size: 24px;
    font-weight: 700;
    color: $color-primary;

    @include media("<medium") {
      font-size: 18px;
    }
  }
  
  &--contextServices &-text {
    font-size: 20px;
    color: $color-white;
  }
}