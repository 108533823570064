.headerLogo {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  cursor: pointer;
  
  img {
    vertical-align: middle;
    width: 203px;
  }

  &--responsive img {
    width: 100%;
  }
}