.input {
  @include input();
  
  /*&-el {
    -webkit-appearance: none;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    line-height: 24px;
    padding: 12px 16px;
    width: 100%;
    box-sizing: border-box;
  
    &:focus {
      outline: none;
      border-color: $color-blue-whale;
      box-shadow: 0 0 0 4px rgba($color-blue-whale, 0.2);
    }
  }*/
}