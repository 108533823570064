.whatWeDo {
  display: flex;
  margin: -16px;

  @include media("<medium") {
    flex-direction: column;
  }
  
  &-col {
    flex: 0 0 percentage(1/4);
    max-width: percentage(1/4);
    padding: 16px;
    box-sizing: border-box;

    @include media("<medium") {
      flex: 0 0 auto;
      max-width: none;
    }
  }
  
  
  &-item {
    text-align: center;
  }

  &-pictureHolder {
    width: 60px;
    height: 60px;
    color: $color-blue-whale;
    display: inline-block;
    padding: 16px;
    border-radius: 46px;
    border: 3px dotted $color-blue-whale;
  }
  
  &-picture {
    width: 60px;
    height: 60px;
  }
  
  &-description {
    margin-top: 24px;
    font-size: 24px;
    font-weight: 700;

    @include media("<medium") {
      font-size: inherit;
    }
  }
}