.navigation {

  &-container {
    position: relative;

    @include media("<medium") {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &-col {
    &--logo {

    }

    &--list {
      @include media("<medium") {
        width: 0;
        flex: 0 1 0;
        overflow: visible;
      }
    }

    &--opener {
      @include media(">=medium") {
        display: none;
        width: 0;
        flex: 0 1 0;
        overflow: hidden;
      }
    }
  }

  &-logo {
    width: 150px;

    @include media(">=medium") {
      display: none;
    }
  }

  &-sandwich {
    @include buttonReset();

    width: 40px;
    height: 40px;
    position: relative;
    padding: 10px;
    box-sizing: border-box;
    color: $color-blue-whale;
    z-index: 22;
    cursor: pointer;
  }

  &-sandwichIcon {
    height: 2px;
    width: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -10px;
    margin-top: -1px;
    background-color: transparent;
    transition: background-color .1s ease-out;

    &::before,
    &::after {
      content: "";
      height: inherit;
      width: inherit;
      position: absolute;
      top: 0;
      left: 0;
      background-color: currentColor;
      transition: all .2s ease-out;
    }

    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }

  &--closed &-sandwichIcon {
    background-color: currentColor;

    &::before {
      transform: translate(0, -8px);
    }
    &::after {
      transform: translate(0, 8px);
    }
  }

  &-list {

    @include media("<medium") {
      position: absolute;
      width: 280px;
      right: 0;
      top: 0;
      background-color: #fff;
      box-shadow: 0 3px 8px rgba(131, 131, 131, .17);
      z-index: 19;
      transform-origin: 100% 0;
      opacity: 1;
      transition: all .2s ease-out 0s,
      z-index 0s linear 0s;
    }
  }

  &--closed &-list {
    @include media("<medium") {
      transform: scale(.6);
      z-index: -1;
      opacity: 0;
      transition: all .2s ease-out 0s,
      z-index 0s linear .2s;
    }
  }

  &-listTitle {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 16px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    color: $color-grey;

    @include media(">=medium") {
      display: none;
    }
  }
  
  &-link {
    text-decoration: none;
    color: inherit;
    display: inline-block;
    padding: 16px  0;
    margin-right: 16px;
    border-bottom: 3px solid transparent;
    vertical-align: middle;

    font-size: 14px;
    text-transform: uppercase;

    &:focus {
      
    }
    
    &:hover {
      border-bottom-color: $color-primary;
    }
    
    &:active {
      
    }

    &:last-child {
      margin-right: 0;
    }

    @include media("<medium") {
      width: 100%;
      box-sizing: border-box;
      padding-left: 16px;
      padding-right: 16px;
      margin-right: 0;
    }
  }
}

